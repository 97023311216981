export interface ListItem {
  name: string;
  key: string; //uniq id for each item
  value: string;
}

export interface FilterOption {
  name: string; // size, shape...
  options: ListItem[]; //2inchx1inch, square
}

export interface PaginationRange {
  start: number;
  end: number;
  displayText: string;
}

export interface DotCMSNode {
  inode: string;
  keywords: string;
  name: string;
  active: boolean;
  velocityVar: string;
  key: string;
  _typename: string;
}

export type DisplayView = 'grid' | 'list';
export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
