import {
  BlankRollItemInterface,
  EstoreItem,
  OaItemInterface,
  RetailItem,
  RollItem,
  SheetItem,
} from './order-item.interface';

export interface Order {
  entity_id: string;
  state: string;
  status: string;
  shipping_description: string;
  base_discount_amount: number;
  base_grand_total: string;
  base_shipping_amount: string;
  base_subtotal: string;
  base_tax_amount: string;
  increment_id: string;
  created_ts: number;
  updated_ts: number;
  product_groups: Array<string>;
  can_cancel: false;
  cancel_expire_at: number;
  payment: {
    type: string;
    value: string;
  };
  order_items: OrderItems;
  shipments: Shipment[];
  shipping_groups: Array<ShippingGroup>;
  shipping_address: {
    entity_id: string;
    region: string;
    postcode: string;
    lastname: string;
    street: string;
    city: string;
    telephone: string;
    country_id: string;
    firstname: string;
    company: any;
  };
}

export interface OrderAsset {
  id: string;
  asset_id: string;
  name: string;
  size_id: number;
  shape_id: number;
  width: number;
  height: number;
  corner_radius: number;
  created_at: number;
  updated_at: number;
  versions: string[] | any;
  images: {
    small: string;
    medium: string;
    large: string;
    xlarge: string;
  };
  previous_order_item: {
    id: string;
    created_at: number;
    material: string;
    finish_type: string;
    finish_texture: string;
  };
}

export interface OrderItems {
  waiting_approval: OrderItemGroups;
  in_production: OrderItemGroups;
  shipped: OrderItemGroups;
  prepare_for_shipment: OrderItemGroups;
}

export interface OrderItemGroups {
  estore_items: EstoreItem[];
  oa_items: OaItemInterface[];
  blankroll_items: BlankRollItemInterface[];
  roll_items: RollItem[];
  sheet_items: SheetItem[];
  retail_items: RetailItem[];
  service_items: any[];
  pro_items: any[];
  other_items: any[];
}

export interface ShippingGroup {
  type: string;
  name: string;
  shipping_method: string;
  shipping_description: string;
  shipped_method: any;
  estimated_delivery_date: string;
  estimated_delivery_date_to: string;
}

export interface Shipment {
  entity_id: string;
  created_at: string;
  content_type: string;
  tracks: Tracks | any;
}

export interface Tracks {
  entity_id: string;
  track_number: string;
  title: any;
  carrier_code: string;
  track_url: string;
}
