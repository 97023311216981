import { KeyValue } from '@angular/common';
import { AveryCustomGenericConfig } from 'navigation/nav-shared/models/interfaces';
import { FilterOption } from './generic.interface';

// Custom Data Type Section
export type PredesignType = 'dify' | 'diy';

/*
 *   Graphql Payload Schema
 */
export interface PredesignFilterPayload {
  title: string;
  name: string;
  customHeader: string | null;
  customSubheader: string | null;
  customBreadcrumbs: KeyValue<string, string>[]; //No data return empty array
  acgs: KeyValue<string, string>[]; //No data return empty array
  accordions: KeyValue<string, string>[]; //No data return empty array
  dynamicConfig: boolean;
  predesign: PredesignType;
  compareSizeLink: string | null;
  compareSize: KeyValue<string, string>[]; //No data return empty array
  sizeOptions: KeyValue<string, string>[]; //No data return empty array
  filterOptions: KeyValue<string, string>[]; //No data return empty array
  metaTitle: string | null;
  metaDescription: string | null;
  viewAllLink: KeyValue<string, string>[];
  sortOrder: number;
}
export interface PredesignEventPayload {
  display: string;
  name: string;
  value: string;
}
export interface PredesignFilterGraphqlPayload {
  averypredesignconfigCollection: PredesignFilterPayload[];
  averypredesigntypeconfigCollection: PredesignEventPayload[];
  averyCustomGenericConfigCollection: AveryCustomGenericConfig[];
}
export interface PredesignFilterConfig {
  categories: PredesignFilterPayload[];
  events: PredesignEventPayload[];
  acgs: AveryCustomGenericConfig[];
}

/*
 *   Final Data Schema
 */
export interface PredesignTemplate {
  featured: boolean;
  shape: string;
  predesignName: string;
  predesignCategory: string[];
  description: string | null;
  title: string;
  eventTypes: string[];
  designThemeName: string;
  imageUri: string;
  predesign: string;
  size: string;
  popularity: number;
  sortOrder: number;
  orientation: string;
  predesignUsage: string[];
  printedOn: string;
  sku: string;
}
export interface PredesignCategory {
  display: string;
  value: string;
}
export interface PredesignEvent {
  urlDisplay: string;
  display: string;
  value: string;
}

// Display/Value appears on all filters in order to display text for the categories in the sidebar
export interface PredesignFilter {
  acgs?: PredesignAcg[];
  compareSizeList?: PredesignCompareSize[];
  customBreadcrumbs?: PreBreadcrumb[];
  customHeader?: string;
  customSubheader?: string;
  display: string;
  dynamicConfig?: boolean;
  filterList?: PredesignFilterSet[];
  predesign?: string;
  metaDescription?: string | null;
  metaTitle?: string | null;
  sizeOptions?: PredesignSizeOption[];
  value: string;
}

export interface PreBreadcrumb {
  name: string;
  path: string;
}

export interface PredesignCustomBreadcrumb {
  breadcrumb: string;
  title: string;
  url: string;
}

export interface PredesignAcg {
  body: string;
  name: string;
  title?: string;
  value: string;
}

// FIXME: Rely on FilterSet from online-aisle(?) branch instead when merged and if possible
export interface PredesignFilterSet {
  expanded: boolean;
  component?: string;
  display: string;
  options: any[];
  value: string;
}

export interface PredesignCompareSize {
  shape: string;
  size: string;
  sizeUri: string;
}

export interface PredesignSizeInfo {
  key: string;
  price: string;
  previewImgSrc: string;
}

export interface PredesignSizeOption extends PredesignCompareSize {
  option1?: string;
  option2?: string;
  viewAllLink?: string;
  sizePrice: string;
}

export interface PredesignCategoryInfo {
  category: string;
  customHeader: string;
  customSubheader: string;
  metaTitle: string;
  metaDescription: string;
  viewAllLink: KeyValue<string, string>[];
}

export interface PredesignFilterOption {
  key: string; //
  options: FilterOption[]; // square, 2x1inch.......
}

export interface PricingHeader {
  base_price: number;
  qty: string;
  materials: string[];
  start_price: string;
  start_qty: number;
}

export interface MaterialPricing {
  original_price: string;
  disc_price: string;
}
export interface PricingBody {
  base_price: string;
  discount_percent: string;
  qty: number;
  materials: MaterialPricing[];
  numOfUnits: string;
  unitDesc: string;
}

export interface Pricing {
  pricing_header: PricingHeader;
  pricing_body: PricingBody[];
}
