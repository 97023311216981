import capitalize from 'lodash-es/capitalize';
import isEmpty from 'lodash-es/isEmpty';
import startCase from 'lodash-es/startCase';
import { fromBrowser } from './urlModification';

/**
 * Function that will return the context needed for DY events
 *
 * @export
 * @returns {Context}
 */
export function getContext(): DYContext {
  const context = { type: '', data: [] };
  const path = document.location.pathname.split('/');
  path.shift();

  // The cases are top-level routes
  switch (path[0]) {
    case 'blank': {
      if (path[1] === 'shape' || path[1] === 'usage' || path[1] === 'material') {
        // Blank Label Filter
        context.type = 'CATEGORY';
        context.data = ['Blank Labels', startCase(path[1]), startCase(path[2])];
      } else {
        // Blank Label Detail
        context.type = 'PRODUCT';
        context.data = [path[2]];
      }
      break;
    }
    case 'custom-printing': {
      if (path[1] === 'predesign') {
        // Predesign Filter Page
        context.type = 'CATEGORY';
        context.data = ['Custom Printing', 'Predesigns', startCase(path[2]), startCase(path[3])];
      } else if (path[1] === 'labels' && path[2] === 'calculator') {
        // Weprint Calculator Page
        context.type = 'PRODUCT';
        context.data = [path[3]];
      }
      break;
    }
    case 'industrial': {
      if (path[1] === 'blank-labels-and-signs') {
        // Industrial Blank Labels
        if (path[2] === 'material' || path[2] === 'usage' || path[2] === 'shape') {
          // Industrial Blank Labels Filter Page
          context.type = 'CATEGORY';
          context.data = ['Industrial', startCase(path[1]), startCase(path[2]), startCase(path[3])];
        } else if (path[2] === 'labels') {
          // Industrial Blank Labels Product Detail Page
          context.type = 'PRODUCT';
          context.data = [path[3]];
        }
      } else if (path[1] === 'custom-printing') {
        // Industrial Custom Printing/WePrint
        if (path[3] === 'calculator') {
          // Industrial Custom Printing Calculator/Detail Page
          context.type = 'PRODUCT';
          context.data = [path[3]];
        }
      } else if (path[1] === 't2c') {
        // Industrial T2C
        if (path[2] === 'designs' || path[2] === 'themes') {
          // Industrial T2C Filter
          context.type = 'CATEGORY';
          context.data = ['Industrial', 'T2C', startCase(path[2]), startCase(path[3])];
        } else if (path[2] === 'calculator') {
          // Industrial T2C Calculator/Detail Page
          context.type = 'PRODUCT';
          context.data = [path[4]];
        }
      } else if (path[1] === 'products') {
        // Industrial CPG Products
        if (Number(path[3])) {
          // Industrial CPG Product/Detail Page
          context.type = 'PRODUCT';
          context.data = [path[3]];
        } else {
          // Industrial CPG Filter Page
          const params = path.map((item) => {
            return startCase(item).replace('Ghs', 'GHS');
          });
          context.type = 'CATEGORY';
          context.data = params;
        }
      }

      break;
    }
    case 'myaccount': {
      break;
    }
    case 'predesign': {
      if (path.length === 3) {
        // Predesign DIFY Filter page
        context.type = 'CATEGORY';
        context.data = ['Predesigns', startCase(path[1]), startCase(path[2])];
      }
      break;
    }
    case 'products': {
      if (path.length === 2) {
        // Product Filter
        let product = path[1];

        switch (product) {
          case 'binderaccessories':
            product = 'Binder Accessories';
            break;
          case 'folderpockets':
            product = 'Folders & Pockets';
            break;
          case 'gluesticks':
            product = 'Glue Sticks';
            break;
          case 'namebadges':
            product = 'NameTagsBadges';
            break;
          case 'sheetprotectors':
            product = 'Sheet Protectors';
            break;
          case 'stamppadinkers':
            product = 'Stamp Pads & Inkers';
            break;
          default:
            product = startCase(product);
        }

        context.type = 'CATEGORY';
        context.data = [product];
      } else if ((path[1] === 'labels' || path[1] === 'cards') && path.length > 3) {
        // Product Filter - Labels or Cards
        context.type = 'CATEGORY';
        context.data = path[1] === 'labels' ? ['Labels'] : ['Cards'];
        context.data.push(startCase(path[3]));
      } else if (path.length === 3) {
        // Product Detail
        context.type = 'PRODUCT';
        context.data = [path[path.length - 1]];
      }
      break;
    }
    case 'srch': {
      break;
    }
    case 'secure': {
      break;
    }
    case 'templates': {
      if (path[1] === 'category') {
        // Template Filter
        const category = fromBrowser(path[2])
          .split(' ')
          .map((word: string) => capitalize(word))
          .join(' ');
        context.type = 'CATEGORY';
        context.data = ['Templates', category];
      } else if (path.length === 2) {
        // Template Detail
        context.type = 'PRODUCT';
        context.data = [`${path[1].replace('presta-', '')}_t`];
      }
      break;
    }
    case 't2c': {
      if (path[1] === 'designs' || path[1] === 'themes') {
        // T2C Filter
        context.type = 'CATEGORY';
        context.data = ['T2C', startCase(path[2]), startCase(path[3])];
      } else if (path[1] === 'calculator') {
        // T2C Calculator/Detail Page
        context.type = 'PRODUCT';
        context.data = [path[4]];
      }
    }
  }

  // If no routes are met, then the DY context fallback will be 'OTHER'
  if (isEmpty(context.type)) {
    context.type = 'OTHER';
  }

  return context;
}

/**
 * Emit a DY event for template downloads
 *
 * @export
 * @param {string} templateUri
 * @param {string} [fileType='']
 */
export function DYTemplateDownloads(templateUri: string, fileType: string = '') {
  try {
    DY.API('event', {
      name: 'template-download',
      properties: {
        templateNumber: templateUri,
        type: fileType,
      },
    });
  } catch (O_o) {
    console.error(O_o);
  }
}
