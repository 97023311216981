import { SafeHtml } from '@angular/platform-browser';

export interface ACG<T extends SafeHtml | string> {
  name: string;
  body: T;
}

export * from './blank-label.interface';
export * from './braintree.interface';
export * from './breadcrumbs.interface';
export * from './ccpa.interface';
export * from './custom-generic.interface';
export * from './file-state.interface';
export * from './file.interface';
export * from './filter.interface';
export * from './industrial.interface';
export * from './item.interface';
export * from './generic.interface';
export * from './ngrx.interface';
export * from './order.interface';
export * from './order-item.interface';
export * from './order-state.interface';
export * from './predesign.interface';
export * from './product.interface';
export * from './profile.interface';
export * from './project-state.interface';
export * from './project.interface';
export * from './roll-state.interface';
export * from './rolls-quote.interface';
export * from './search.interface';
export * from './static-content.interface';
export * from './stencil.interface';
export * from './template.interface';
export * from './transparency.interface';
export * from './weprint-calculator.interface';
export * from './weprint-calculator-config.interface';
export * from './weprint-rolls.interface';
export * from './weprint-sheets.interface';
export * from './weprint-stickers.interface';
