import { combineReducers } from '@ngrx/store';

import {
  Action,
  GET_PRODUCT_IMAGES,
  GET_PRODUCT_GROUP,
  RESET_PRODUCT,
  SELECT_IMAGE,
  SELECT_PRODUCT,
} from '../actions';

export function SelectedProductReducer(state = {}, action: Action) {
  switch (action.type) {
    case SELECT_PRODUCT:
      return action.payload.product;
    case RESET_PRODUCT:
      return {};
    default:
      return state;
  }
}

export function ProductImagesReducer(state = [], action: Action) {
  switch (action.type) {
    case GET_PRODUCT_IMAGES:
      return action.payload.productImages;
    case RESET_PRODUCT:
      return [];
    default:
      return state;
  }
}

export function SelectedImageReducer(state = '', action: Action) {
  switch (action.type) {
    case SELECT_IMAGE:
      return action.payload.selectedImage;
    case RESET_PRODUCT:
      return '';
    default:
      return state;
  }
}

export function ProductGroupReducer(state = [], action: Action) {
  switch (action.type) {
    case GET_PRODUCT_GROUP:
      return action.payload.productGroup;
    case RESET_PRODUCT:
      return '';
    default:
      return state;
  }
}

export const productReducers = combineReducers({
  selectedProduct: SelectedProductReducer,
  productImages: ProductImagesReducer,
  selectedImage: SelectedImageReducer,
  productGroup: ProductGroupReducer,
});
