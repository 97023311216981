import {
  TransparencySize,
  RollsMaterial,
  RollsFinishTexture,
  CustomRolls,
} from './weprint-rolls.interface';
import { CustomShape } from './weprint-stickers.interface';

export interface TransSelectionStateInterface {
  custom: CustomRolls;
  customShape: CustomShape;
  format?: string;
  size: TransparencySize;
  material: RollsMaterial | any;
  unwindId: number;
  hand_applied: number;
  underPrint?: string;
  labelQty: number;
  fileName: string;
  hideSelections: boolean;
  shape: string;
  designs: number;
  finish: RollsFinishTexture | any;
}

// interface
export interface TransVersionResponse {
  brandId: string;
  firstCode: string;
  lastCode: string;
  name: string;
  quantity: number;
  snapshotId: string;
}

export interface TransparencyQuoteState {
  pricePerLabel: number;
  subTotal: number;
}
