import { isPlatformBrowser } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  ChangeDetectorRef,
  Component,
  ComponentFactory,
  ComponentFactoryResolver,
  ComponentRef,
  ElementRef,
  HostListener,
  Inject,
  NgZone,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  Renderer2,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Store } from '@ngrx/store';
import * as localForage from 'localforage';
import isEmpty from 'lodash-es/isEmpty';
import { Subscription, timer } from 'rxjs';
import { filter, switchMap, take } from 'rxjs/operators';

import { environment } from '../../environments/environment';
import { GET_PROFILE } from '../../ngrx/actions';

// Components
import { EmailQuoteComponent } from '../nav-shared/components/email-quote/email-quote.component';

import { WebcaseEmailUsFormComponent } from '../nav-shared/components/user-forms/webcase-email-us-form/webcase-email-us-form.component';

import { CustomCardsModalComponent } from 'navigation/nav-shared/components/custom-cards-modal/custom-cards-modal.component';
import { CustomGenericModalComponent } from '../nav-shared/components/custom-generic-modal/custom-generic-modal.component';
import { FreeSamplePackModalComponent } from '../nav-shared/components/free-sample-pack-modal/free-sample-pack-modal.component';
import { GenericModalComponent } from '../nav-shared/components/generic-modal/generic-modal.component';
import { InternationalVisitorModalComponent } from '../nav-shared/components/international-visitor-modal/international-visitor-modal.component';
import { SignInModalComponent } from '../nav-shared/components/user-forms/sign-in-modal/sign-in-modal.component';

// Services
import { AuthenticationService } from '../nav-shared/authentication.service';
import { NavigationService } from '../nav-shared/navigation.service';
import { HeaderService } from './header.service';

// Interfaces and Helpers
import * as dayjs from 'dayjs';
import { checkCrdlWithRetry } from 'navigation/nav-shared/helpers/cordial';
import { ModalSize } from 'navigation/nav-shared/models/types';
import { AvryModalService } from 'navigation/nav-shared/services/avry-modal.service';
import {
  createCookie,
  emitProductClick,
  emitPromoImpressions,
  getCookie,
  getQueryParamByName,
  localStorageGetter,
  localStorageRemover,
  localStorageSetter,
  removeCookie,
} from '../nav-shared/helpers';
import {
  AveryCustomGenericInterface,
  ClickProducts,
  GenericModal,
  Stencil,
  TokenInterface,
} from '../nav-shared/models/interfaces';

declare const loadScripts: any; // Declare the loadScripts function

@Component({
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  @ViewChild('modalContainer', { read: ViewContainerRef, static: true })
  container;

  accessToken: { u_at: string; expiry: number };
  active = false;
  cartLink = `${this.headerService.magentoUrl}/checkout/cart/`;
  cartItemCount: any;
  commonJSRenderer: any;
  avyVendorScripts: any;
  helpLinkUrl: string;
  isAuthenticated: boolean = false;
  isMobile: boolean;
  isTabletView: boolean;
  mobileSearchField: boolean = false;
  myAccountOpen: boolean = false;
  openMobileNav: boolean = false;
  orderForage: LocalForage;
  pepperjamValue: SafeUrl;
  promoValue: SafeUrl;
  refreshTimeout: any;
  subscriptions: Subscription[] = [];
  themeMode: string = null;
  timerSubscribe: Subscription = null;
  userInitials: string;
  userSignedIn: boolean;
  onLink: boolean = false;
  activeNavMenu: number = -1;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private _ngZone: NgZone,
    private authService: AuthenticationService,
    private headerService: HeaderService,
    private navigationService: NavigationService,
    private ref: ChangeDetectorRef,
    private renderer: Renderer2,
    private store: Store<any>,
    public elm: ElementRef,
    private avryModalService: AvryModalService,
    private componentFactoryResolver: ComponentFactoryResolver
  ) {
    // Allows this component to be referenced outside of app
    if (isPlatformBrowser(this.platformId)) {
      window['navHeaderRef'] = { component: this, zone: this._ngZone };
    }
  }

  ngOnInit() {
    this.orderForage = localForage.createInstance({ name: 'Avery Search' });
    this.checkWindowSize();
    this.checkCartCountCookie();

    this.addCommonJS();

    this.loadVendorScripts();

    this.addPJClickJS();

    if (this.elm.nativeElement.getAttribute('data-host') !== null) {
      this.headerService.magentoUrl = `${
        window.document.location.protocol
      }//${this.elm.nativeElement.getAttribute('data-host')}.avery.com`;
    }
    this.accessToken = localStorageGetter('u_at');
    if (this.accessToken) {
      this.calculateTimeForRefreshToken(this.accessToken.expiry);
    }

    this.checkQueryParams();

    this.subscriptions = [
      this.cordialSubscription(),
      this.mobileNavActiveSubscription(),
      this.myAccountActiveSubscription(),
      this.notifyForeignVisitorSubscription(),
      this.searchActiveSubscription(),
      this.themeModeSubscription(),
      this.userDataSubscription(),
      this.userSignedInSubscription(),
    ];
  }

  ngOnDestroy() {
    this.renderer.removeChild(document.head, this.commonJSRenderer);
    this.subscriptions.forEach((sub) => sub.unsubscribe());
    if (this.refreshTimeout) {
      this.refreshTimeout.clearTimeout();
    }
  }

  /**
   * Update isMobileView and isTabletView BS on window resize
   *
   * @param event
   * @memberof HeaderComponent
   */
  @HostListener('window:resize', ['$event'])
  onWindowResize(event) {
    this.checkWindowSize();
  }

  /**
   * [Cordial] Emit cordial events on every page
   * @returns {Subscription}
   * @memberof HeaderComponent
   */
  cordialSubscription(): Subscription {
    return this.navigationService.routeChanged.subscribe((route: string) => {
      checkCrdlWithRetry(`${environment.domain}${route}`);
    });
  }

  /**
   * Return subscription for Mobile Nav Activation.
   *
   * @returns {Subscription}
   * @memberof HeaderComponent
   */
  mobileNavActiveSubscription(): Subscription {
    return this.headerService.mobileNavActive.subscribe(
      (value) => (this.openMobileNav = value)
    );
  }

  /**
   * Return subscription for My Account Menu Activation.
   *
   * @returns {Subscription}
   * @memberof HeaderComponent
   */
  myAccountActiveSubscription(): Subscription {
    return this.headerService.myAccountActive.subscribe(
      (value) => (this.myAccountOpen = value)
    );
  }

  /**
   * Return subscription for My Account Menu Activation.
   *
   * @returns {Subscription}
   * @memberof HeaderComponent
   */
  searchActiveSubscription(): Subscription {
    return this.headerService.searchActive.subscribe(
      (value) => (this.mobileSearchField = value)
    );
  }

  /**
   * Return subscription for the theme mode.
   *
   * @returns {Subscription}
   * @memberof HeaderComponent
   */
  themeModeSubscription(): Subscription {
    return this.navigationService.themeMode.subscribe((mode: string) => {
      this.themeMode = mode;

      if (mode === 'industrial') {
        this.helpLinkUrl = `${environment.domain}/help/industrial/kb`;
      } else {
        this.helpLinkUrl = `${environment.domain}/help/`;
      }

      // authenticate user after setting theme mode to avoid using wrong DY id
      if (!this.userSignedIn && !this.isAuthenticated) {
        this.authenticateLogin();
        this.isAuthenticated = true;
      }
    });
  }

  /**
   * Calculates the difference for next time the refresh token gets checked.
   *
   * @param { number } expirationTime
   * @memberOf HeaderComponent
   */
  calculateTimeForRefreshToken(expirationTime: number = null) {
    let expirationDate: dayjs.Dayjs = null;
    if (!isEmpty(expirationTime)) {
      expirationDate = dayjs(expirationTime);
    } else {
      expirationDate = dayjs().add(1, 'hour');
    }
    const alertTime = dayjs();
    const durationTime = expirationDate.diff(alertTime);
    this._refreshToken(durationTime);
  }

  /**
   * Refreshes the token within the given time.
   *
   * @param durationTimer
   * @private
   * @memberOf HeaderComponent
   */
  private _refreshToken(durationTimer: number) {
    // TODO: refresh the token here!
    if (!isEmpty(this.timerSubscribe)) {
      // do something here
      this.timerSubscribe.unsubscribe();
    }
    this.timerSubscribe = timer(durationTimer).subscribe(() => {
      this.authService
        .refreshToken()
        .pipe(switchMap(this.handleAuthentication.bind(this)))
        .subscribe((data: any) => {
          this.store.dispatch({
            type: GET_PROFILE,
            payload: {
              email: data.email,
              firstName: data.firstName,
              lastName: data.lastName,
              optIn: data.optIn,
            },
          });
          localStorageSetter('u_i', JSON.stringify(data));
          this.authService.userSignedInUpdate(true);
          this.calculateTimeForRefreshToken();
        }, this.handleAuthenticationErr.bind(this));
    });
  }
  /**
   * Get the initals of the signed in user
   *
   * @returns {subscription}
   * @memberof HeaderComponent
   */
  userDataSubscription(): Subscription {
    return this.store.select('profile', 'profileData').subscribe((data) => {
      this.userInitials =
        data.firstName.split('')[0] + data.lastName.split('')[0];
    });
  }

  /**
   * Return subscription for User Signed In.
   *
   * @returns {Subscription}
   * @memberof HeaderComponent
   */
  userSignedInSubscription(): Subscription {
    return this.authService.userSignedIn.subscribe(
      (signedIn) => (this.userSignedIn = signedIn)
    );
  }

  /**
   * Check cookies to see if item count cookie is set and returns the value
   *
   * @memberof HeaderComponent
   */
  checkCartCountCookie() {
    this.cartItemCount = getCookie('avy_ic') ? getCookie('avy_ic') : 0;
  }

  /**
   * Check the size of the window and update the isMobileView BS
   *
   * @memberof HeaderComponent
   */
  checkWindowSize() {
    const mobileSize = this.navigationService.isMobile();
    const tabletSize = this.navigationService.isTablet();

    if (this.isTabletView !== tabletSize) {
      this.headerService.shadowOverlay.next(false);
      this.headerService.mobileNavActive.next(false);
      this.headerService.navMenuActive.next(false);
      this.headerService.myAccountActive.next(false);

      if (!tabletSize) {
        this.navigationService.isTabletView.next(false);
      } else {
        this.navigationService.isTabletView.next(true);
      }
      this.isTabletView = tabletSize;
    }

    if (this.isMobile !== mobileSize) {
      this.headerService.shadowOverlay.next(false);
      this.headerService.mobileNavActive.next(false);
      this.headerService.navMenuActive.next(false);
      this.headerService.myAccountActive.next(false);

      if (!mobileSize) {
        this.navigationService.isMobileView.next(false);
      } else {
        this.navigationService.isMobileView.next(true);
      }
      this.isMobile = mobileSize;
    }
  }

  /**
   * Toggle open the search field for mobile view
   *
   * @memberof HeaderComponent
   */
  toggleSearch() {
    if (!this.mobileSearchField) {
      this.headerService.searchActive.next(true);
      this.headerService.mobileNavActive.next(false);
      this.headerService.myAccountActive.next(false);
      this.headerService.shadowOverlay.next(true);
    } else {
      this.headerService.searchActive.next(false);
      this.headerService.shadowOverlay.next(false);
    }
  }

  /**
   * Open the mobile nav
   *
   * @memberof HeaderComponent
   */
  toggleMobileNav() {
    if (!this.openMobileNav) {
      this.headerService.mobileNavActive.next(true);
      this.headerService.myAccountActive.next(false);
      this.headerService.searchActive.next(false);
      this.headerService.shadowOverlay.next(true);
    } else {
      this.headerService.mobileNavActive.next(false);
      this.headerService.shadowOverlay.next(false);
    }
  }

  /**
   * Close menu (hover off)
   *
   * @memberof HeaderComponent
   */
  offNavigation() {
    if (!this.isTabletView) {
      this.headerService.navMenuActive.next(false);
      this.headerService.shadowOverlay.next(false);
    }
  }

  /**
   * toggle open My Account menu (hover off)
   *
   * @memberof HeaderComponent
   */
  toggleMyAccount() {
    if (!this.myAccountOpen) {
      this.headerService.navMenuActive.next(false);
      this.headerService.mobileNavActive.next(false);
      this.headerService.myAccountActive.next(true);
      this.headerService.searchActive.next(false);
      this.headerService.shadowOverlay.next(true);
    } else {
      this.headerService.myAccountActive.next(false);
      this.headerService.shadowOverlay.next(false);
    }
  }

  /**
   * Check for valid query params and perform whatever action needed
   *
   * @memberof HeaderComponent
   */
  checkQueryParams() {
    const params: string[] = ['wpc', 'wpch', 'Click_ID']; // Add more query params to this array if needed to be checked
    const validQueryParams: string[] = [];

    // Look out for only valid query params
    params.forEach((param: string) => {
      const value = getQueryParamByName(param);

      if (value !== null) {
        validQueryParams.push(param);
      }
    });

    /**
     * Indiviual logic to handle each param
     */

    // Promo Code Value
    const wpcValue = validQueryParams.includes('wpc')
      ? this.headerService.sanitizeURL(getQueryParamByName('wpc'))
      : null;
    // Promo Code Hide Status
    const wpcHideStatus = validQueryParams.includes('wpch')
      ? this.headerService.sanitizeURL(getQueryParamByName('wpch'))
      : null;

    // NOTE: If the 'wpcHideStatus' exists, it should NOT show the promo code in the
    // messaging but be replaced by a default/generic message (while still accommodating the
    // 'wpc' parameter). Any other values in 'wpcHideStatus' will simply disable the
    // promo code messaging entirely.
    if (wpcHideStatus) {
      if (wpcHideStatus === '1') {
        this.promoValue = 'default';
        createCookie('wpch', wpcHideStatus, 1, '.avery.com');
      } else {
        createCookie('wpch', '0', 1, '.avery.com');
      }

      if (wpcValue) {
        createCookie('wpc', wpcValue, 1, '.avery.com');
      }

      // NOTE: 'wpc' will only be accepted if 'wpch' is never present in the query parameters.
    } else if (wpcValue) {
      this.promoValue = wpcValue;

      createCookie('wpc', this.promoValue, 1, '.avery.com');
      createCookie('wpch', '0', 1, '.avery.com');
    }

    if (
      validQueryParams.includes('Click_ID') &&
      window.location.href.includes('/custom-printing/')
    ) {
      this.pepperjamValue = this.headerService.sanitizeURL(
        getQueryParamByName('Click_ID')
      );
      createCookie('PJclickID', this.pepperjamValue, 90, '.avery.com');
    }
  }

  /**
   * Make the initial call to authenticate the user.
   *
   * @memberof HeaderComponent
   */
  async authenticateLogin() {
    // variables for authentication
    const accessToken = localStorageGetter('u_at');
    const userInfo = localStorageGetter('u_i');
    const refreshToken = localStorageGetter('u_rt');
    try {
      const validUser: boolean = await this.authService
        .validateAuth()
        .toPromise();
      if (validUser) {
        this.subscriptions.push(
          this.authService
            .getToken()
            .pipe(switchMap(this.handleAuthentication.bind(this)))
            .subscribe({
              next: (data: any) => {
                this.store.dispatch({
                  type: GET_PROFILE,
                  payload: {
                    email: data.email,
                    firstName: data.firstName,
                    lastName: data.lastName,
                    optIn: data.optIn,
                  },
                });
                localStorageSetter('u_i', JSON.stringify(data));
                this.authService.userSignedInUpdate(true);
                this.authService.DYEventIdentifyUser(data.consumerId);

                if (isEmpty(this.timerSubscribe)) {
                  this.calculateTimeForRefreshToken();
                }
              },
              error: this.handleAuthenticationErr.bind(this),
            })
        );
      } else {
        this.removeAuthenticationData();
      }
    } catch (error) {
      if (!environment.production) {
        console.error(error);
      }
      this.removeAuthenticationData();
    }
  }

  /**
   *  Validate authentication.
   * @param {TokenInterface} authData
   * @memberOf HeaderComponent
   */
  handleAuthentication(authData: TokenInterface) {
    const accessData = JSON.stringify({
      u_at: authData.data.access_token,
      expiry: Date.now() + 60 * 60 * 1000,
    });
    // NOTE: I need to move the u_at and u_rt to be a cookie

    localStorageSetter('u_at', accessData);
    if (
      authData.status !== 'token_refreshed' ||
      isEmpty(localStorageGetter('u_rt'))
    ) {
      localStorageSetter('u_rt', authData.data.refresh_token);
    }

    return this.authService.getUserInfo(authData.data.access_token);
  }

  /**
   * Handle error response for unauthenticated users.
   *
   * @param {HttpErrorResponse} err
   * @memberOf HeaderComponent
   */
  handleAuthenticationErr(err: HttpErrorResponse) {
    if (!environment.production) {
      console.error(err.error);
    }
    this.removeAuthenticationData();
  }

  /**
   * Handle removing all of the stored authentication data.
   */
  removeAuthenticationData() {
    this.authService.removeItems();
    localStorageRemover('u_at');
    localStorageRemover('u_i');
    localStorageRemover('u_rt');
    removeCookie(environment.authKey, '.avery.com');
    removeCookie('btid');
    removeCookie('stencilUser');
    this.authService.userSignedInUpdate(false);
    this.authService.stencilExists.next(false);
    if (!isEmpty(this.timerSubscribe)) {
      // NOTE: No need to check for a token, if user is not logged on. Also, it
      // makes sure to clean up the subscription if the user is no logged in.
      this.timerSubscribe.unsubscribe();
    }
  }

  /**
   * Get cached stencil data and verify if user is a stencil user.
   *
   * @return {Promise<void>}
   */
  async getCachedStencils(): Promise<void> {
    try {
      const stencil: Array<Stencil> | any = await this.orderForage.getItem(
        'stencilList'
      );
      if (!isEmpty(stencil)) {
        this.authService.stencilExists.next(true);
      } else {
        const stencilUser = getCookie('stencilUser');
        if (stencilUser) {
          this.authService.stencilExists.next(true);
        } else {
          this.authService.stencilExists.next(false);
        }
      }
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Used by dotcms pages through zonejs
   * Open the custom card modal
   *
   * @param {string} customCardType
   * @param {string} customCardCategory
   * @param {string} modalSize
   * @memberof HeaderComponent
   */
  openCustomCardModal(
    customCardType: string,
    customCardCategory?: string,
    modalSize?: 'sm' | 'lg'
  ) {
    this.container.clear();
    const modalCompRef: ComponentRef<CustomCardsModalComponent> = this.container.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(
        CustomCardsModalComponent
      )
    );

    modalCompRef.instance.id = `modal-for-${customCardType}`;
    modalCompRef.instance.size = 'md';
    modalCompRef.instance.cardType = customCardType;
    modalCompRef.instance.categoryName = customCardCategory;
  }

  /**
   * Open Sign In modal for Design & Print when not signed in.
   * If opening a saved project, upload modal will open after.
   * GA/GTM tracking can be enabled by supplying GA parameters in the `trackGTM` parameter.
   * redirectPath redirects the user after signing in or having been signed in.
   *
   * @param {string} buttonName
   * @param {any} [trackGTM={}]
   * @param {string} redirectPath
   * @memberof HeaderComponent
   */
  openDPOModal(
    buttonName: string,
    trackGTM = {},
    redirectPath: string,
    callback
  ) {
    if (!this.userSignedIn) {
      this.container.clear();
      const modalCompRef: ComponentRef<SignInModalComponent> = this.container.createComponent(
        this.componentFactoryResolver.resolveComponentFactory(
          SignInModalComponent
        )
      );
      modalCompRef.instance.redirectLink = redirectPath;
      if (callback) {
        modalCompRef.instance.onClose
          .pipe(take(1))
          .subscribe((value) => value === 'success' && callback());
      }
    } else {
      window.location.href = redirectPath;
    }
  }

  /**
   * Open Generic Modal displaying ACG content from CMS
   * or an Image and Title from DAM.
   *
   * @param {GenericModal} content
   * @param {string} classes
   * @param { ModalSize } size
   * @memberof HeaderComponent
   */
  openGenericModal(
    content: GenericModal,
    classes: string = '',
    size?: ModalSize
  ) {
    this.container.clear();
    const genericModalFactory: ComponentFactory<GenericModalComponent> = this.componentFactoryResolver.resolveComponentFactory(
      GenericModalComponent
    );
    const genericModalCompRef: ComponentRef<GenericModalComponent> = this.container.createComponent(
      genericModalFactory
    );
    genericModalCompRef.instance.id = 'modal-for-generic';
    genericModalCompRef.instance.size = size || 'md';
    genericModalCompRef.instance.genericModalContent = content;
  }

  /**
   * Open Sample Pack Modal.
   *
   * @memberof HeaderComponent
   */
  openSamplePackModal() {
    this.container.clear();
    const fspModalFactory: ComponentFactory<FreeSamplePackModalComponent> = this.componentFactoryResolver.resolveComponentFactory(
      FreeSamplePackModalComponent
    );
    const fspModalCompRef: ComponentRef<FreeSamplePackModalComponent> = this.container.createComponent(
      fspModalFactory
    );

    fspModalCompRef.instance.id = 'modal-for_free_sample_pack';
  }

  /**
   * Open web case email us form.
   *
   * @memberof HeaderComponent
   */
  openWebCaseEmailUsModal() {
    this.avryModalService.init(
      this.container,
      WebcaseEmailUsFormComponent,
      'webcase-email-us-form-modal',
      'lg'
    );
  }

  /**
   * Open ACG Modal.
   *
   * @param {string} type
   * @param {string} urlTitle
   * @param {boolean} noTitle
   * @param {string} classes
   * @memberof HeaderComponent
   */
  popAveryCustomGenericModal(
    urlTitle: string,
    type: string,
    noTitle: boolean = false,
    classes?: string
  ) {
    setTimeout(() => {
      this.navigationService
        .fetchAveryCustomGenericContent(urlTitle, type)
        .pipe(filter((data: AveryCustomGenericInterface) => !isEmpty(data)))
        .subscribe((data: AveryCustomGenericInterface) => {
          this.container.clear();
          const customGenericFactory: ComponentFactory<CustomGenericModalComponent> = this.componentFactoryResolver.resolveComponentFactory(
            CustomGenericModalComponent
          );
          const customGenericCompRef: ComponentRef<CustomGenericModalComponent> = this.container.createComponent(
            customGenericFactory
          );
          customGenericCompRef.instance.customGenericModalData = data;
          customGenericCompRef.instance.noTitle = noTitle;
          customGenericCompRef.instance.utilClasses = `modal-avry smooth-scroll ${classes}`;
        });
    }, 1000);
  }

  /**
   * Add Common JS to the Head
   *
   * @memberof HeaderComponent
   */
  addCommonJS() {
    this.commonJSRenderer = this.renderer.createElement('script');
    this.commonJSRenderer.src = `${environment.domain}/app/js/avery-common.js`;

    this.renderer.appendChild(document.head, this.commonJSRenderer);
  }

  /**
   * Add Avy Vendor Scripts
   *
   * @memberof HeaderComponent
   */
  loadVendorScripts() {
    const script = document.createElement('script');
    script.src = `${environment.domain}/app/js/avy-vendors.js`;
    script.onload = () => {
      loadScripts(); // Call the loadScripts function after vendors.js is loaded
    };
    document.body.appendChild(script);
  }

  /**
   * Add PepperJam Click JS to html body
   *
   * @memberof HeaderComponent
   */
  addPJClickJS() {
    this.commonJSRenderer = this.renderer.createElement('script');
    this.commonJSRenderer.src = `${environment.domain}/app/js/avery-pepperjam-click.js`;

    this.renderer.appendChild(document.body, this.commonJSRenderer);
  }

  /**
   * Update stencil data in localForage.
   *
   * @param {Array<Stencil>} stencilList
   * @memberof HeaderComponent
   */
  updateStencilStorage(stencilList: Array<Stencil>) {
    this.orderForage.setItem('stencilList', stencilList);
  }

  /**
   * Allow dotcms pages to emit GA product click events
   *
   * @param {string} [field='']
   * @param {ClickProducts[]} [products=[]]
   * @memberof HeaderComponent
   */
  executeGAProductClick(field: string = '', products: ClickProducts[] = []) {
    emitProductClick(field, products);
  }

  /**
   * Allow dotcms pages to emit GA promotion (Impression and Click) events
   *
   * @memberof HeaderComponent
   */
  executeGAPromo() {
    emitPromoImpressions();
  }

  /**
   * Trigger modal notifying Non-USA Avery visitors to continue to Avery.com USA website or be redirected to their Avery local website (if available).
   *
   * NOTE: Will not show up if opted-in to continue US site. Settings saved in a cookie, which expires in a day.
   *
   * @returns {Subscription}
   * @memberof HeaderComponent
   */
  notifyForeignVisitorSubscription(): Subscription {
    // Country Codes and matching cookie keys that will notify a foreign visitor (Australia, Canada, and New Zealand).
    const countryCookies: any = {
      AU: 'asnzm',
      CA: 'cnm',
      NZ: 'asnzm',
    };
    return this.navigationService.getCountryCode().subscribe((res) => {
      const countryCode: string = res.headers.get('cloudfront-viewer-country');
      const cookieCheck: string = getCookie(countryCookies[countryCode])
        ? getCookie(countryCookies[countryCode])
        : '';
      const notifyUser: boolean =
        countryCookies.hasOwnProperty(countryCode) &&
        !countryCookies.hasOwnProperty(cookieCheck);

      // Canada selector modal should not appear only on '/custom-printing/partners/recipal'
      // TODO: remove disableNotification when not needed
      const disableNotification: boolean =
        window.location.pathname === '/custom-printing/partners/recipal/'
          ? countryCode === 'CA'
          : false;
      if (notifyUser && !disableNotification) {
        const cookieName = countryCookies[countryCode];

        // NOTE: In Develop Mode, you will get an `ExpressionChangedAfterItHasBeenCheckedError` error, caused by the
        // Canadian Visitor modal. This is because there might be settings/configurations that are not ready when
        // the modal triggers (possibly due to async). This only occurs in the Develop build.
        setTimeout(() => {
          this.container.clear();
          const intVisitorModalFactory: ComponentFactory<InternationalVisitorModalComponent> = this.componentFactoryResolver.resolveComponentFactory(
            InternationalVisitorModalComponent
          );
          const intVisitorModalCompRef: ComponentRef<InternationalVisitorModalComponent> = this.container.createComponent(
            intVisitorModalFactory
          );
          intVisitorModalCompRef.instance.id = 'modal-for_int_visistor';
          intVisitorModalCompRef.instance.size = 'md';
          intVisitorModalCompRef.instance.cookieName = cookieName;
          intVisitorModalCompRef.instance.countryCode = countryCode;
        }, 3000);
      }
    });
  }

  /**
   * Opens the EMail Quote Form.
   *
   * @memberof HeaderComponent
   */
  openEmailQuote(
    title: string,
    btntext: string,
    acgCompletionTitle: string,
    acgCompletionRequestSuccess: string,
    acgCompletionRequestError: string,
    acgMainModalSubtitle: string,
    acgMainModalPrivacy: string,
    isQueueSubmission: string
  ) {
    this.container.clear();

    const modalCompRef: ComponentRef<EmailQuoteComponent> = this.container.createComponent(
      this.componentFactoryResolver.resolveComponentFactory(EmailQuoteComponent)
    );
    modalCompRef.instance.title = title;
    modalCompRef.instance.btntext = btntext;
    modalCompRef.instance.acgCompletionTitle = acgCompletionTitle;
    modalCompRef.instance.acgCompletionRequestSuccess = acgCompletionRequestSuccess;
    modalCompRef.instance.acgCompletionRequestError = acgCompletionRequestError;
    modalCompRef.instance.acgMainModalSubtitle = acgMainModalSubtitle;
    modalCompRef.instance.acgMainModalPrivacy = acgMainModalPrivacy;
    modalCompRef.instance.isQueueSubmission = isQueueSubmission;
  }

  /**
   * Open mega-menu (on hover) after a dealay and only if the mouse is still on the menu item.
   * @param {number} i
   *
   * @memberof HeaderNavComponent
   */
  onDesktopNavLink(i: number) {
    if (this.activeNavMenu !== i) {
      this.activeNavMenu = i;
      this.headerService.navMenuActive.next(true);
      this.headerService.myAccountActive.next(false);
      this.headerService.shadowOverlay.next(true);
    }
  }

  /**
   * cancel navigation dropdown by setting onLink variable to false.
   *
   * @memberof HeaderNavComponent
   */
  cancelNavLink() {
    this.activeNavMenu = -1;
    this.headerService.navMenuActive.next(false);
    this.headerService.myAccountActive.next(false);
    this.headerService.shadowOverlay.next(false);
  }
}
