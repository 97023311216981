import { AltPreset } from './weprint-calculator.interface';
import { HelpModalInterface } from './weprint-calculator-config.interface';
import { Accordion } from './static-content.interface';

export type BlankLabels = BlankLabel[];

export interface BlankLabel {
  cornerType?: string;
  configType?: string;
  description: string;
  defaultMediaUrl: string;
  difyProductNumber: string;
  diyProductNumber: string;
  facePrint: boolean;
  // featured: boolean;
  height: number;
  labelCategory: string;
  labelsPerSheet: number;
  leftPageMargin?: string;
  pageSize?: string;
  popularity: number;
  printToTheEdge: boolean;
  productDisplayName: string;
  productNumber: string;
  shape: string;
  size: string;
  sortOrder?: number;
  width: number;
  topPageMargin?: string;
  type?: string;
  viewOrientation?: string;
}

export interface BlankLabelDetail extends BlankLabel {
  acgs: BlankLabelAcgs;
  alt?: { [key: string]: any }[];
  displayXSell: boolean;
  labelRelationExists?: boolean;
  materials: Array<BlankLabelMaterial>;
  media: AltPreset[];
  metaDescription: null;
  metaTitle: string;
  startingPrice: number;
  templateNumber: string;
  usage: Array<string>;
}

export interface BlankLabelSearchResult extends BlankLabel, BlankLabelDetail {
  templateNumber: string;
  searchKeywords: any;
  contentType: string;
  productNumber: string;
  compatibleSkus: any;
}

export interface BlankLabelFilter {
  acgs: BlankLabelAcgs;
  configType: string;
  contentlets?: BlankLabel[];
  customSize: boolean;
  defaultLabelCategory?: string;
  description: string;
  display: string;
  imageUri: string;
  magentoMaterialKey: string;
  metaDescription?: string;
  metaTitle?: string;
  value: string;
}

export interface BlankLabelAcgs {
  'help-modal-material': HelpModalInterface;
  'helpful-links': HelpModalInterface;
  'helpful-links-no-template': HelpModalInterface;
  'help-modal-printer-type': HelpModalInterface;
  materials: HelpModalInterface;
  accordions: Array<Accordion>;
  'csc-hours': HelpModalInterface;
  ksp: HelpModalInterface;
  seo: HelpModalInterface;
  'product-info': HelpModalInterface;
  'product-info-no-template': HelpModalInterface;
}

export interface BlankLabelPrinter {
  type_value: string;
  type_label: string;
  material_id: number;
}

export interface BlankLabelMaterial {
  displayOrder?: number;
  faceprint: boolean;
  features?: any[];
  imageUri?: string;
  material_base: string;
  materialDescription: string;
  material_label: string;
  material_type: string;
  material_value: string;
  nonfeatures?: any[];
  printers: Array<BlankLabelPrinter>;
}

export interface BlankLabelPrice {
  label_qty: number;
  msrp_price: number;
  price: number;
  product?: any;
  qty: number;
  saving_amount: number;
  saving_percent: number;
  unit_price: number;
}

export interface BlankLabelCustom {
  shape: string;
  dpo_sku: string;
  dpo_description: string;
  dpo_short_description: string;
  dpo_size_description: string;
  preview_url: string;
  dpo_sku_response: string;
  panel_count: string;
  ptte: string;
  panel_height: string;
  panel_width: string;
  top_page_margin: string;
  left_page_margin: string;
  page_size: string;
  dpo_corner_type: string;
  viewOrientation: string;
}

export interface BlankLabelSnapshot {
  projectSnapshotId: string;
  projectStencilId: string;
  templateId: string;
  sku: string;
  includesWhiteColor: string;
  hints: {
    name: string;
    value: string;
  }[];
  sheets: number;
  deploymentId: string;
  fullSheetPreviewUrl: string;
  proofPdfUrl: string;
  resourceLocation: {
    storage: string;
    path: string;
  };
  checkDesign: string;
}

export interface TemplateIdDto {
  id: string;
  layoutVersion: string;
  shape: string;
  width: number;
  height: number;
  cornerRadius: number;
  ptte: string;
  dpoSizeDescription: string;
  dpoShortDescription: string;
  topPageMargin: string;
  leftPageMargin: string;
  pageSize: string;
  dpoCornerType: string;
  panelCount: number;
  viewOrientation: string;
}

export interface StencilWithTemplateDto {
  id: string;
  deploymentId: string;
  name: string;
  sku: string;
  productGroupName: string;
  productProfile: string;
  creationTime: number;
  modificationTime: number;
  uriXml: string;
  uriPreview: string;
  profile: string;
  productDto: {
    sku: string;
    sizeDescription: string;
    longDescription: string;
    colorDescription: string;
  };
  templateIdDto: TemplateIdDto;
}
