import { Order } from './order.interface';

export interface OrderState {
  orderPeriods: OrderPeriods;
  sortType: string;
  approvalList: Array<Order>;
  inProductionList: Array<Order>;
  prepShippingList: Array<Order>;
  shippedList: Array<Order>;
  cancelledList: Array<Order>;
  orderStatus: string;
  total: number;
  displayAmt: number;
  refreshList: string;
}

export interface OrderPeriods {
  orderTime: string;
  aryProcess30Days: Array<Order>;
  aryProcess6Months: Array<Order>;
  aryProcess12Months: Array<Order>;
  aryComplete30Days: Array<Order>;
  aryComplete6Months: Array<Order>;
  aryComplete12Months: Array<Order>;
}
