export interface Stencil {
  id: string;
  name: string;
  skuName: string;
  productGroupName: string;
  productProfile: string;
  deploymentId: string;
  createdTime: number;
  modificationTime: number;
  productDto: {
    sku: string;
    sizeDescription: string;
    longDescription: string;
    colorDescription: string;
  };
  uriXML: string;
  uriPreview: string;
  stencilOrders?: Array<string>;
}
