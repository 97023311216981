import { isPlatformBrowser, Location } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit, PLATFORM_ID, Renderer2 } from '@angular/core';
import { Event, NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter, map } from 'rxjs/operators';
import { environment } from '../environments/environment';
// Services
import { HeaderService } from '../navigation/header/header.service';
import { NavigationService } from '../navigation/nav-shared/navigation.service';
import { getContext } from './shared/helpers';

@Component({
  selector: 'app-root',
  template: `
    <app-header *ngIf="!isIFrame"></app-header>
    <main class="page-content" [ngClass]="{ 'page-loaded': pageLoaded }">
      <router-outlet></router-outlet>
      <avy-google-review-badge></avy-google-review-badge>
      <avy-live-chat-widget></avy-live-chat-widget>
    </main>
  `,
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isIFrame: boolean = false;
  savedRoute: string = '';
  shouldEmitDYSpaEvent: boolean = false;
  shouldEmitGAEvent: boolean = false;
  subscriptions: Subscription[];
  themeMode: string;
  pageLoaded: boolean = false;

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    private headerService: HeaderService,
    private location: Location,
    private navigationService: NavigationService,
    private renderer: Renderer2,
    private router: Router
  ) {}

  ngOnInit() {
    this.subscriptions = [this.averyThemeModeSubscription(), this.routerEventsSubscription()];
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  /**
   * Returns subscription that handles theme mode.
   *
   * @returns {Subscription}
   * @memberof AppComponent
   */
  averyThemeModeSubscription(): Subscription {
    return this.navigationService.themeMode
      .pipe(filter((type: string) => this.themeMode !== type))
      .subscribe((type: string) => {
        if (type) {
          this.themeMode = type;
          const bodyElement = document.getElementsByTagName('body')[0];

          // Set class to current theme (as long as it is not 'default')
          if (type !== 'default') {
            this.renderer.setAttribute(bodyElement, 'data-avry-mode', type);
            // Else remove data attribute
          } else {
            this.renderer.removeAttribute(bodyElement, 'data-avry-mode');
          }
        } else {
          // Initial theme mode check on this subscription init, if no type is configured yet
          this.navigationService.setThemeMode();
        }
      });
  }

  /**
   * Checks for route change and if it is NavigationEnd, performs functions below.
   *
   * @returns {Subscription}
   * @memberof AppComponent
   */
  routerEventsSubscription(): Subscription {
    return this.router.events
      .pipe(
        filter((event: Event) => event instanceof NavigationEnd),
        map((event: NavigationEnd) => event.url.split('?'))
      )
      .subscribe(([route, _queryParams]: [string, string]) => {
        // since we disabled router module for the nav standalone, need to use Event Emitter to listen to route change
        this.navigationService.emitRouteChange(route);
        this.emitDYSpaEvent(route);
        this.resetNavigation(route);
        this.pageLoaded = true;
        this.isIFrame = route === '/industrial/ghs-subscription-payment';
      });
  }

  /**
   * Resets the navigation
   *
   * @param {string} route
   * @memberof AppComponent
   */
  resetNavigation(route: string) {
    if (route !== this.savedRoute && isPlatformBrowser(this.platformId)) {
      window.scroll(0, 0);
      this.savedRoute = route;
      this.shouldEmitDYSpaEvent = true;
      this.headerService.navMenuActive.next(false);
      this.headerService.myAccountActive.next(false);
      this.headerService.mobileNavActive.next(false);
      this.headerService.searchActive.next(false);
      this.headerService.shadowOverlay.next(false);
    }
  }

  /**
   * Emit a SPA event to DY
   *
   * @memberof AppComponent
   */
  emitDYSpaEvent(route: string) {
    if (this.shouldEmitDYSpaEvent && this.savedRoute !== route) {
      try {
        DY.API('spa', {
          context: getContext(),
          url: environment.domain + this.location.path(),
          countAsPageview: true,
        });
      } catch (err) {
        console.error(err);
      }
    }
  }
}
