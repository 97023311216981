import { RollsBuyMoreEstimate } from './rolls-quote.interface';
import { OrderAsset } from './order.interface';
import { CustomShape } from './weprint-stickers.interface';
import { FormatSize } from './weprint-calculator-config.interface';
import { ItemsPrice } from './weprint-calculator.interface';

export interface Measurement {
  diameter?: number;
  height?: number;
  width?: number;
}

export interface CustomRolls {
  status?: string;
  selected: boolean;
  rectangle: Measurement;
  round: Measurement;
  oval: Measurement;
  square: Measurement;
  squareCorners: boolean;
}

export interface RollsShape {
  img_path: string;
  name: string;
  description: string;
  is_custom: number;
  id: number;
  enabled: number;
}

export interface RollsSize extends FormatSize {
  corner_radius: number;
  dpo_product_group: string;
  img_path: string;
  scene7_code?: string;
  type?: string;
  dpo_sku: string;
  shape_id: number;
  name: string;
  id: number;
  die_no: number;
  die_id?: number;
  materials?: Array<TransparencyMaterials>;
  spec: string;
}

export interface RollsFinishTypes {
  laser_material: boolean;
  name: string;
  id: number;
  colors: RollsColor[];
}

export interface RollsFinishTexture {
  finish_types: RollsFinishTypes[];
  name: string;
  id: number;
}

export interface RollsColor {
  name: string;
  id: number;
}

export interface RollsSelectionInterface {
  format: string;
  shape: string;
  custom: CustomRolls;
  customShape: CustomShape;
  size: RollsSize | any;
  material: RollsMaterial | any;
  finish: RollsFinishTexture | any;
  designs: number;
  labelQty: number;
  unitType: string;
}

export interface RollsSelectionStateInterface extends RollsSelectionInterface {
  dpoProductGroup: string;
  dpoSku: string;
  estimateSelected: RollsBuyMoreEstimate;
  finishType: RollsFinishTypes;
  proofType: string;
  underPrint: string;
  unwindId: number;
  hand_applied: number;
  unwindPending?: number;
}

export interface ReorderStateInterface {
  list: Array<OrderAsset>;
  selected: Array<OrderAsset>;
}

export interface RollsMaterial {
  features: any[];
  finish_textures: RollsFinishTexture[];
  hard_proof: boolean;
  id: number;
  name: string;
  active?: boolean;
}

export interface RollsConfig {
  square: RollsSize[];
  round: RollsSize[];
  materials: RollsMaterial[];
  custom_materials?: RollsMaterial[];
  multiDesigns?: boolean;
  shapes: RollsShape[];
  oval: RollsSize[];
  rectangle: RollsSize[];
  customSize?: boolean;
}

export interface RollsMachineInterface {
  imgurl: string;
  description: string;
  id: number;
  descAddToCart: string;
}

export interface TransparencyMaterials {
  id: number;
  itemsPriceList?: ItemsPrice[];
  name: string;
  prices?: Array<TransparencyPrice>;
}
export interface TransparencySize extends RollsSize {
  die_id: number;
  materials: Array<TransparencyMaterials>;
  scene7_code?: string;
  type: string;
}

export interface TransparencyPrice extends RollsBuyMoreEstimate {
  qty: number;
  saving: number;
}
