import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';
import isEmpty from 'lodash-es/isEmpty';
import unescape from 'lodash-es/unescape';

// Interface
import { AveryCustomGenericInterface } from '../../../../navigation/nav-shared/models/interfaces';

// Service
import { AppService } from '../../../app.service';

@Component({
  selector: 'app-dynamic',
  template: `
    <div
      class="d-inline"
      [innerHTML]="acgContent$ | async"
      [attr.data-acg-title]="acgUrlTitle"
    ></div>
    <ng-content *ngIf="unloaded"></ng-content>
  `,
})
export class AcgComponent implements OnInit {
  @Input() acgType: string;
  @Input() acgUrlTitle: string;

  unloaded: boolean = true;

  acgContent$: Observable<SafeHtml>;
  constructor(private appService: AppService, private sanitizer: DomSanitizer) {}

  ngOnInit() {
    this.initialize();
  }

  private initialize() {
    this.acgContent$ = this.appService.fetchCustomGeneric(this.acgType, this.acgUrlTitle).pipe(
      filter((data: AveryCustomGenericInterface) => !isEmpty(data)),
      tap(() => (this.unloaded = false)),
      map((data: { body: string }) => this.sanitizer.bypassSecurityTrustHtml(unescape(data.body)))
    );
  }
}
