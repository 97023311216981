import { TemplateIdDto } from './blank-label.interface';

export interface Project {
  applyDesignDisabled: boolean;
  creationTime: number;
  crossMappingExist: boolean;
  deploymentId: string;
  id: string;
  modificationTime: number;
  name: string;
  productDto: ProductDto | null;
  templateIdDto?: TemplateIdDto;
  productGroupName: string;
  productProfile: string;
  quoteDto: {
    shapeId: string;
    totalVersions: number;
    versionNames: string[];
  };
  relatedProjectId: null;
  sku: string;
  stencilOrders?: string[];
  trademark: any;
  type?: string;
  usage?: string;
}

export interface ProductDto {
  sku?: string;
  sizeDescription?: string;
  longDescription?: string;
  colorDescription?: string;
}
