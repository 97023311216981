// TODO: This state interface is subject to change based upon the requirements

import { VersionOption } from './rolls-quote.interface';
import {InputBase} from '../classes/input-base';

export interface RollStateInterface {
  shapeId: string;
  sizeId: string;
  material: string;
  design: string;
  quantity: number;
  printing_cost: number;
  option_selected: number;
  order_more_options: Array<any>;
};

export interface ArtworksInterface extends VersionOption {
  previewUrls?: {
    large: string;
    medium: string;
    small: string;
    xlarge: string;
  };
}

export interface MageArtworksInterface extends VersionOption {
  preview_urls?: {
    large: string;
    medium: string;
    small: string;
    xlarge: string;
  }
}
export interface RollFormGroup {
  aryInputs: Array<InputBase<any>>;
  previewUrls?: {
    large: string;
    medium: string;
    small: string;
    xlarge: string;
  }
}
