import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { Observable, Subscription } from 'rxjs';

@Directive({
  selector: '[averyCrRenderIf]',
})
export class RenderIfDirective {
  private _observable: Observable<boolean>;
  private _subscription!: Subscription;

  @Input()
  set averyCrRenderIf(value: Observable<boolean>) {
    this._observable = value;
    if (this._observable) {
      this.subscribe();
    }
  }

  constructor(private viewContainer: ViewContainerRef, private templateRef: TemplateRef<any>) {}

  ngOnDestroy(): void {
    this.unsubscribe();
  }

  ngOnInit() {
    this.viewContainer.clear();
  }

  private subscribe() {
    this._subscription = this._observable.pipe().subscribe({
      next: (render: boolean) => {
        if (render) {
          this.viewContainer.createEmbeddedView(this.templateRef);
        } else {
          this.viewContainer.clear();
        }
      },
      complete: () => {
        this.unsubscribe();
      },
    });
  }

  private unsubscribe() {
    if (this._subscription) {
      this._subscription.unsubscribe();
    }
  }
}
