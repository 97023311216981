import { CustomSize, SheetsMaterial, SheetsSize } from './';

export interface SheetsSelectionInterface {
  format: string;
  shape: string;
  scallop?: boolean;
  size: SheetsSize | any;
  customSize: CustomSize;
  material: SheetsMaterial | any;
  quantity: ItemsPrice | any;
  unitType: string;
}

export interface PresetConfig {
  sheets: Preset[];
  rolls: Preset[];
  stickers: Preset[];
}

export interface Preset {
  color?: number;
  die_id: number;
  finishTexture?: number;
  finishType?: number;
  shape: string;
  material: number;
  mediaUrl: string;
  qty: number;
  sortOrder: number;
  metaTitle?: string;
  imageDescription: any;
  alt?: string;
  type: string;
  sku: string;
  poster?: string;
  videoUrl?: string;
  youtubeID?: string;
}

export interface AltPreset {
  name?: string;
  mediaUrl: string;
}

export interface WePrintQueryParams {
  artworkId?: string;
  customSize?: string;
  designs?: string;
  finish?: string;
  handApplied?: string;
  height?: string;
  imageUrl?: string;
  material?: string;
  qty?: string;
  quoteUrlImageSm?: string;
  quoteUrlImageMed?: string;
  scallop?: string; // for sheets only: 'Rounded', 'Smooth' or 'Scalloped', Title: Edge or Corners
  shape?: string;
  size?: string;
  sqCorner?: string; // for rolls only: 'Rounded' or 'Squared' for shapes rectangle and square only
  status?: string;
  unitType?: string;
  unwindId?: string;
  width?: string;
}

export interface LabelsEmailQuoteFormInterface {
  email: string;
  fName: string;
  lName: string;
  phone?: string;
  company?: string;
  format?: string;
}

export interface LabelsEmailQuoteInterface extends LabelsEmailQuoteFormInterface {
  customSize?: string;
  designs: number;
  finish?: string;
  format?: string;
  hardProof?: string;
  imgUrl: string;
  material: string;
  message?: string;
  quantity: number;
  quoteUrl: string;
  scallop?: string;
  scallopText?: string;
  shape: string;
  size: string;
  squareCorners?: string;
  timeReq: string;
  totalPrice: string;
  unitPrice: string;
  unitType?: string;
}

export interface MaterialModal {
  imageUri: string;
  imageUriIcon: string;
  materialDescription: string;
  material_label: string;
}

export interface ItemsPrice {
  price: number;
  qty: number;
  numSheets: number;
  itemsPerSheet: number;
  pricePerLabel: number;
  disc: any;
  basePrice: number;
}

export interface MaterialConfig {
  addWhiteColor: boolean;
  includeWhiteUnderprint: boolean;
  calcType: string;
  imageUri: string;
  name: string;
  description: string;
  displayNameRolls?: string;
  displayNameSheets?: string;
  features: any[];
  label: string;
  imageUriIcon: string;
  materialOrder: number;
}

export interface MaterialConfigContainer {
  materials: MaterialConfig[];
}
