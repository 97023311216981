import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcgModule } from '../acg/acg.module';
import { ErrorComponent } from './error.component';
import { NavigationService } from 'navigation/nav-shared/navigation.service';



@NgModule({
  declarations: [ErrorComponent],
  imports: [
    CommonModule,
    AcgModule
  ],
  exports: [ ErrorComponent],
  providers: [ NavigationService]
})
export class ErrorModule { }
