export class ProductWithType {
  contentType: string;
  hasType: boolean;
  type: string;
  subtype: string;

  constructor(contentType = '', hasType = false, type = '', subtype = '') {
    this.contentType = contentType;
    this.hasType = hasType;
    this.type = type;
    this.subtype = subtype;
  }
}
