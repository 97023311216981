/**
 * Displays info for 404 errors. Use Object.assign() to change the display property
 *
 * @export
 * @class NotFoundError
 */
export class NotFoundError {
  display: boolean;
  mainMessage: string;
  errorMessage: string;

  constructor() {
    this.display = false;
    this.mainMessage = 'It looks like the page you want doesn’t exist.';
    this.errorMessage = 'Error Code: <span class="error-code">404</span>';
  }
}

/**
 * Displays info for dotCMS errors. Use Object.assign() to change the display property
 *
 * @export
 * @class DotCMSError
 */
export class DotCMSError {
  display: boolean;
  mainMessage: string;
  errorMessage: string;

  constructor() {
    this.display = false;
    this.mainMessage = 'Something went terribly wrong, but don\'t despair...';
    this.errorMessage = 'We\'ll have this back up and running in no time ;-)';
  }
}
