export interface RetailItem extends OrderItem {
  product: ItemProduct;
  details: any;
}

export interface OaItemInterface extends OrderItem {
  product: ItemProduct;
  details: OAItemDetails;
}
export interface BlankRollItemInterface extends OrderItem {
  product: ItemProduct;
  details: BlankRollItemDetails;
}

export interface AttrInfo {
  label: string;
  value: string;
}
export interface OrderVersion {
  version_id: string;
  increment_number: string;
  name: string;
  qty: string;
  asset_id: string;
  artwork_id: any;
  status: string;
  proof_url?: string;
  proof_status: string;
  proof_status_updated_at: number;
  proof_status_by: any;
  transparency_brand_id?: any;
  transparency_first_code?: any;
  transparency_last_code?: any;
  thumbnail_url: string;
  can_be_approved: boolean;
}

export interface LabelProduct extends ItemProduct {
  items_per_sheet: any;
  sheets_per_unit: any;
  averypro_number: any;
}

export interface ItemProduct {
  entity_id: string;
  sku: string;
  accessory_cross_sell?: string;
  thumbnail: string;
  small_image: string;
}

export interface RollItem extends OrderItem {
  product: LabelProduct;
  details: RollItemDetail;
}

export interface SheetItem extends OrderItem {
  details: ItemDetail;
  product: LabelProduct;
}

export interface EstoreItem extends OrderItem {
  product: ItemProduct;
  details: any;
}
export interface OrderItem {
  item_id: string;
  name: string;
  product_id: string;
  product_type: string;
  qty_ordered: number;
  row_total: string;
  sku: string;
  status: string;
}

export interface RollSize extends StandardRollSize, CustomRollSize {}
export interface StandardRollSize {
  die_id: string;
  name: string;
  die_no: string;
  scene7_code: string;
  spec: string;
  height: string;
  width: string;
  corner: string;
  shape_id: string;
  dpo_sku: string;
  dpo_product_group: string;
  rotated_die_id: string;
  enabled: string;
  app_group?: string;
  transparency_enabled?: string;
  transparency_unwind_id?: string;
  transparency_configuration_code?: any;
  sort_order: string;
}

export interface CustomRollSize {
  corner_radius: string;
  height: string;
  shape_id: string;
  width: string;
}

export interface ItemDetail {
  artwork_id?: string;
  attributes_info: any;
  project_snapshot_id?: string;
  sheets: number;
  thumbnail_url: string;
  type?: string;
}

export interface RollItemDetail {
  label_quantity: string;
  artwork_source: string;
  artwork_project_id: string;
  proof_type: string;
  total_versions: string;
  hand_applied: string;
  underprint: string;
  unwind_pending: string;
  transparency_enabled: string;
  unwind: string;
  application: string;
  size: RollSize;
  attributes_info: AttrInfo[];
  versions: OrderVersion[];
  can_be_approved: boolean;
  proof_status: string;
  proof_status_updated_at: any;
  proof_status_by: any;
}

export interface OAItemDetails {
  template: {
    code: string;
    name: string;
    labels_per_sheet: number;
    size: string;
    height: string;
    width: string;
    shape: string;
    image_url: string;
    is_custom_size?: string;
    project_template_id?: string;
    project_snapshot_id?: string;
    project_stencil_id?: string;
    project_size_description?: string;
  };
  material: {
    code: string;
    name: string;
    material_base: string;
    material_type: string;
    material_key: string;
    printer_type: string;
    faceprint: string;
    value_name: string;
  };
}
export interface BlankRollItemDetails {
  material: {
    material_id: number;
    code: string;
    name: string;
    roll_width: number;
    label_finish: string;
    label_material: string;
    printer_type: string;
  };
  template: {
    template_id: number;
    code: string;
    name: string;
    size: string;
    shape: string;
    height: number;
    width: number;
    image_code: string;
  };
  thumbnail_url: string;
  finished_roll_width: number;
  core: number;
  od: number;
  feet_per_roll: number;
  labels_per_roll: number;
}
