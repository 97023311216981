import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoldAwareSectionComponent } from './fold-aware-section.component';
import { RenderIfDirective } from './directives/render-if/render-if.directive';
import { ObserveVisibilityDirective } from './directives/observe-visibility/observe-visibility.directive';

@NgModule({
  declarations: [FoldAwareSectionComponent, RenderIfDirective, ObserveVisibilityDirective],
  imports: [CommonModule],
  exports: [FoldAwareSectionComponent],
})
export class FoldAwareSectionModule {}
