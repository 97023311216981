// TODO: Use shared Breadcrumb interface
export interface WeprintCalcBreadcrumb {
  title: string;
  url: string;
}

export interface CalculatorContent {
  configType?: string;
  customBreadcrumbTitle?: string;
  defaultMediaUrl: string;
  dpoDesignCategoryName?: string;
  hideDesignCategories?: boolean;
  extraBreadcrumb?: WeprintCalcBreadcrumb[];
  rolls: InfoInterface;
  sheets: InfoInterface;
  stickers: InfoInterface;
  startingPrice: number;
  subTitle: string;
  title: string;
}

// TODO: Rename to something helpful
export interface InfoInterface {
  banner: HelpModalInterface;
  'csc-hours': HelpModalInterface;
  faqs?: FaqInterface[];
  helpModals: HelpModalInterface[];
  ksp: HelpModalInterface;
  'ksp-calc': HelpModalInterface;
  materials: HelpModalInterface;
  materialsDisplay: string;
  metaDescription: string;
  metaTitle: string;
  'product-features': HelpModalInterface;
  reviews: HelpModalInterface;
  'reviews-banner': HelpModalInterface;
  seo?: HelpModalInterface;
  subTitle?: string;
}

// TODO: Rename this to something more generic
export interface HelpModalInterface {
  name?: string;
  title: string;
  body: string;
}

export interface FaqInterface {
  question: string;
  answer: string;
}

export interface SpecialCalculatorConfig {
  type: string;
  additionalSections?: string[];
  defaultSections?: string[];
  defaultSelections?: {
    createCookieRolls?: string;
    createCookieSheets?: string;
    createCookieStickers?: string;
    format?: string;
    designs?: number;
    quoteLimitText?: string;
    quotePrice?: number;
    quoteQty?: number;
    rollsHelpModalFinish?: string;
    sheetsScalloped?: string;
  };
  excludeRollsMaterials?: string[];
  excludeRollsShapes?: string[];
  excludeSheetsMaterials?: string[];
  excludeSheetsShapes?: string[];
  hiddenComponents?: string[];
  hiddenSections?: string[];
  includeShapeAndSizeComboSheets?: ComboSheets;
  includeShapeAndSizeComboRolls?: any;
  materialModalAnchor?: string[];
}

export interface ComboSheets {
  shape: string;
  size: string[];
}
export interface SpecialCaseConfig {
  customSize?: boolean;
  name?: string;
  rolls?: boolean;
  searchable?: boolean;
  sheets?: boolean;
  stickers?: boolean;
  specialConfigRolls?: boolean;
  specialConfigSheets?: boolean;
  specialConfigStickers?: boolean;
  status?: boolean;
}

export interface SpecialConfig {
  customSize?: boolean;
  specialConfigRolls: boolean;
  specialConfigSheets: boolean;
  showRollsFormat: boolean;
  specialConfigStickers: boolean;
  name: string;
  showSheetsFormat: boolean;
  showStickersFormat: boolean;
  searchable: boolean;
}
export interface FormatSize {
  heightFraction?: string;
  height_fraction?: string;
  height_decimal?: string;
  widthFraction?: string;
  width_fraction?: string;
  height?: number;
  width?: number;
  width_decimal: string;
  sizeThumbnail: any;
  sku: string;
}
