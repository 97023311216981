import {
  File,
  FileState,
  FilterState,
  OrderState,
  ProductState,
  ProfileState,
  Project,
  Stencil,
  TemplateState,
} from './';

export interface StoreInterface {
  filter: FilterState;
  product: ProductState;
  filterFiles: FileState;
  myaccount: {
    filter: {
      displayAmt: number;
      sortType: string;
      total: number;
    };
    files: Array<File>;
    projects: Array<Project>;
    stencils: Array<Stencil>;
  };
  template: TemplateState;
  profile: ProfileState;
  order: {
    filterOrder: OrderState;
  };
  transparency: any;
  rolls: any;
}
