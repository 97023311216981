import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';

import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';

// Modules
import { FooterModule } from '../navigation/footer/footer.module';
import { HeaderModule } from '../navigation/header/header.module';
import { NewsletterSignupModule } from '../navigation/nav-shared/components/newsletter-signup/newsletter-signup.module';

// Components
import { AppComponent } from './app.component';

// Other local files to utilize in the AppModule
import { AppService } from './app.service';
import { environment } from '../environments/environment';
import { AuthenticationService } from '../navigation/nav-shared/authentication.service';
import { reducerProvider, reducerToken } from '../ngrx/reducers';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AcgModule } from './shared/components/acg/acg.module';
import { ErrorModule } from './shared/components/error/error.module';
import { FoldAwareSectionModule } from './shared/optimization/fold-aware-section/fold-aware-section.module';
import { EffectsModule } from '@ngrx/effects';

const apolloProvider = {
  provide: APOLLO_OPTIONS,
  useFactory: (httpLink: HttpLink) => {
    return {
      cache: new InMemoryCache(),
      link: httpLink.create({
        // TODO: Add to environment
        uri: `${environment.domain}/api/v1/graphql`,
      }),
    };
  },
  deps: [HttpLink],
};

const NavigationModules = [NewsletterSignupModule, FooterModule, HeaderModule];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AcgModule,
    ApolloModule,
    AppRoutingModule,
    BrowserModule,
    BrowserAnimationsModule,
    ErrorModule,
    FoldAwareSectionModule,
    HttpClientModule,
    StoreModule.forRoot(reducerToken),
    ...NavigationModules,
    !environment.production ? StoreDevtoolsModule.instrument({ maxAge: 50 }) : [],
    EffectsModule.forRoot([]),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [AuthenticationService, AppService, apolloProvider, reducerProvider],
  bootstrap: [AppComponent],
})
export class AppModule {}
