import { Product } from './product.interface';
import { Template } from './template.interface';

export interface Bucket {
  key: string;
  doc_count: number;
}

export interface SearchResponse {
  aggregations: {
    interactions: {
      buckets: Bucket[];
    };
  };
  hits: {
    hits: SearchResult[];
    max_score: number;
    total: number;
  };
  timed_out: boolean;
  took: number;
  _shards: {
    failed: number;
    skipped: number;
    successful: number;
    total: number;
  };
  type?: {
    originalType: string;
    finalType: string;
  };
}

export interface SearchResult {
  _id: string;
  _index: string;
  _score: number;
  _source: Partial<SearchResultSource>;
  _type: string;
}

export interface SearchResultSource extends SearchTile, Article, Product, Template {}

export interface SearchTile {
  category: string;
  contentType: string;
  description: string;
  image: string;
  link: string;
  name: string;
  rank: number;
  searchKeywords: string;
  title: string;
}

export interface SearchResultListDisplay {
  linkURL: string;
  linkText: string;
  resultDescription?: string;
  productImg?: string;
  productDetailList?: any;
  resultType?: string;
}

export interface Article {
  articleCategory: string;
  articlePath: string;
  articleType: string;
  author: string;
  averyContentType: string;
  body: string;
  caption: string;
  contentType: string;
  locationPath: string;
  mediaUrl: string;
  title: string;
  urlTitle: string;
}
