import { Measurement } from './weprint-rolls.interface';
import { ItemsPrice } from './weprint-calculator.interface';
import { FormatSize } from './weprint-calculator-config.interface';

export interface CustomShape {
  selected: boolean;
  status: string;
  artworkId?: string;
  height?: number;
  width?: number;
  diameter?: number;
  sku?: string;
}

export interface StickerMeasurement extends Measurement {
  artworkId?: string;
  cornerRadius?: number;
  dpoSku?: string;
  previewUrl?: string;
  labelsPerSheet?: string;
}

export interface CustomSku {
  dpo_sku: string;
  dpo_descriptions: string;
  preview_url: string;
  dpo_sku_response: string;
  panel_count?: string;
}

export interface CustomSize {
  artworkId?: string;
  selected: boolean;
  shape: string;
  status: string;
  arched?: StickerMeasurement;
  heart?: StickerMeasurement;
  rectangle?: StickerMeasurement;
  round?: StickerMeasurement;
  oval?: StickerMeasurement;
  square?: StickerMeasurement;
  star?: StickerMeasurement;
}

export interface StickersSize extends FormatSize {
  die_id: number;
  group: string;
  name: string;
  oneUpImage?: string;
  productShape?: string;
  shape?: string;
  materials: StickersMaterial[];
  sheetImage: any;
  size: string;
  stickerImage: string;
  labelDescription: string;
}

export interface StickersMaterial {
  features: any[];
  itemsPriceList?: ItemsPrice[];
  label: string;
  material: string;
  materialId: number;
  simpleSku: number;
  tierPrices?: ItemsPrice[];
  value?: number;
}

export interface StickersConfig {
  shape: string;
  sizes: StickersSize[];
}

export interface StickersSelectionInterface {
  customShape: CustomShape;
  customSize: CustomSize;
  format: string;
  imageUrl?: string;
  quantity: ItemsPrice | any;
  material: StickersMaterial | any;
  quoteUrlImageSm: string;
  quoteUrlImageMed: string;
  shape: string;
  size: StickersSize | any;
  unitType: string;
}

export interface StickerArtworkInterface {
  artwork_id: string;
  dpo_sku: string;
  sku?: string;
  errors: any[];
  resized: string;
  name?: string;
  qty?: number;
  shape: string;
  panel_height: number | string;
  panel_width: number | string;
  preview_urls?: {
    large: string;
    medium: string;
    small: string;
    xlarge: string;
  };
  proof_url: string;
  check_design: string;
  check_design_reason: string;
  artwork_status: string;
  artwork_filename: string;
  artwork_url: string;
  artwork_pdf_url: string;
}

export interface DpoQuote {
  addWhiteColor?: string;
  includesWhiteUnderprint?: string;
  profile: string;
  sku: string;
  material: number;
  material_original_attributes?: {
    material: string;
    finish_type: string;
    finish_texture: string;
    color: string;
  };
  qty: number;
  artworkId?: string;
  projectSnapshotId?: string;
  labelShape?: string;
  labelHeight?: number;
  labelWidth?: number;
  cornerRadius?: number | string;
  checkDesign?: string;
  checkDesignReason?: string;
  sheets?: string;
  labelsPerSheet?: string;
  includesWhiteColor?: string;
}
