import { InjectionToken } from '@angular/core';
import { ActionReducerMap } from '@ngrx/store';

import { filterReducers } from './filter';
import { productReducers } from './product';
import { templateReducers } from './templates';
import { FilterProfileState } from './profile';
import { ordersReducer } from './orders';
import { transparencyReducers } from './transparency';
import { rollReducers } from './rolls';
import { myAccountReducer } from './my-account';
import { StoreInterface } from '../../app/shared/models/interfaces';

export const reducerToken = new InjectionToken<ActionReducerMap<StoreInterface>>('Reducers');

export function getReducers() {
  return {
    filter: filterReducers,
    product: productReducers,
    myaccount: myAccountReducer,
    template: templateReducers,
    profile: FilterProfileState,
    order: ordersReducer,
    transparency: transparencyReducers,
    rolls: rollReducers,
  };
}

export const reducerProvider = [{ provide: reducerToken, useFactory: getReducers }];
