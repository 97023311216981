export interface BasicIdentityOrCategory {
  id: string;
  name: string;
  title: string;
  description: string;
  previewUrl: string;
}

export interface DesignTheme extends BasicIdentityOrCategory {
  skus: string[];
  count: number;
}

export interface Theme extends BasicIdentityOrCategory {
  skus: string[];
  tags: string[];
  count: number;
  metadata: string;
}

export interface Metadata {
  panelBleed: string;
  pagePanelsCount: string;
  panelRoundCorner: string;
  panelHeight: string;
  pageViewOrientation: string;
  pagePaperSize: string;
  panelWidth: string;
  panelShape: string;
}

export interface Predesign {
  id: string;
  skus: string[];
  size: string;
  name: string;
  description: string;
  alternativeUse: string;
  previewUrl: string;
  wizardName: string;
  orientation: string;
  designComplexity: number;
  readyMade: boolean;
  metadata: Metadata;
}
