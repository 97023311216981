import {
  Action,
  GET_PROFILE,
  GET_PROFILE_EMAIL,
  GET_PROFILE_NAME,
  UPDATE_PROFILE_STATUS,
} from '../actions';
import { Profile, ProfileState } from '../../app/shared/models';

const profileData: Profile = {
  email: '',
  firstName: '',
  lastName: '',
  optIn: false,
};

const INITIAL_PROFILESTATE: ProfileState = {
  firstName: '',
  lastName: '',
  email: '',
  newEmail: '',
  updateStatus: true,
  profileData,
};

export function FilterProfileState(state = INITIAL_PROFILESTATE, action: Action) {
  switch (action.type) {
    case GET_PROFILE_NAME:
      const profileNameDto = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        profileData: { ...state.profileData, ...action.payload },
      };
      return { ...state, ...profileNameDto };

    case GET_PROFILE_EMAIL:
      const emailDto = {
        email: action.payload.email,
        newEmail: action.payload.email,
        profileData: { ...state.profileData, email: action.payload.email },
      };
      return { ...state, ...emailDto };
    case GET_PROFILE:
      const profileDto = {
        firstName: action.payload.firstName,
        lastName: action.payload.lastName,
        profileData: action.payload,
      };
      return { ...state, ...profileDto };
    case UPDATE_PROFILE_STATUS:
      const statusDto = {
        updateStatus: action.payload.profStatusUpdate,
      };
      return { ...state, ...statusDto };
    default:
      return state;
  }
}
