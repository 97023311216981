import fromPairs from 'lodash-es/fromPairs';

/**
 * Function that modifies a string to make it readable to the browser by preventing weird encoding
 * characters. Also makes it readable for human eyes too! It is important to keep in mind that order
 * of the if logic matters (mostly because of the dashes)
 *
 * @export
 * @param {string} [snippet='']
 * @returns {string}
 */
export function toBrowser(snippet: string = ''): string {
  return snippet
    .toLowerCase()
    .replace(/,/g, '__')
    .replace(/&#44;/g, '__')
    .replace(/-/g, '_')
    .replace(/\//g, '--')
    .replace(/ /g, '-')
    .replace(/&/g, '~');
}

/**
 * Since we need to make elastic search calls, we need to undo the readable url and convert the
 * characters back to its original characters. It is important to also know that order matters here,
 * just like toBrowser().
 *
 * @export
 * @param {string} [snippet='']
 * @returns {string}
 */
export function fromBrowser(snippet: string = ''): string {
  return snippet
    .replace(/__/g, '&#44;')
    .replace(/--/g, '/')
    .replace(/-/g, ' ')
    .replace(/~/g, '&')
    .replace(/_/g, '-');
}

/**
 * Structures the filter query parameters so that it is legible
 *
 * @export
 * @param {Object} filters
 * @returns {string}
 */
export function structureFilterParam(filters: Object): string {
  const keys = Object.keys(filters);
  let param = '';
  keys.forEach((key) => {
    const values = `${filters[key]}`;
    param += `${key}_${values}~`;
  });

  return param.substring(0, param.length - 1);
}

/**
 * Destructures the filter query parameters so that the application can utilize the data
 *
 * @export
 * @param {string} filterString
 * @returns
 */
export function destructureFilterParam(filterString: string) {
  const types = filterString.split('~');

  return fromPairs(
    types.map((type) => {
      const [key, values] = type.split('_');

      return [`${key}`, values.split(',')];
    })
  );
}

/**
 * Structure the toggle query parameter
 *
 * @export
 * @param {string[]} toggle
 * @returns {string}
 */
export function structureToggleParam(toggle: string[]): string {
  return toggle.join(',');
}

/**
 * Destructure the toggle query parameter
 *
 * @export
 * @param {string} toggleString
 * @returns {string[]}
 */
export function destructureToggleParam(toggleString: string): string[] {
  return toggleString.split(',');
}

/**
 * Structure the sort query parameter
 *
 * @export
 * @param {string} sort
 * @returns {string}
 */
export function structureSortParam(sort: string): string {
  switch (sort) {
    case 'Price: Low - High':
      return 'price-low-to-high';
    case 'Price: High - Low':
      return 'price-high-to-low';
    case 'Product Name: A - Z':
      return 'name-a-to-z';
    case 'Product Name: Z - A':
      return 'name-z-to-a';
    case 'Product #: Low - High':
      return 'product-num-low-to-high';
    case 'Product #: High - Low':
      return 'product-num-high-to-low';
    case 'Size: Small - Large':
      return 'size-sm-to-lg';
    case 'Size: Large - Small':
      return 'size-lg-to-sm';
    case 'Most Popular':
    default:
      return 'most-popular';
  }
}

/**
 * Destructure the sort query parameter
 *
 * @export
 * @param {string} sort
 * @returns {string[]}
 */
export function destructureSortParam(sort: string): any {
  switch (sort) {
    case 'price-low-to-high':
      return 'Price: Low - High';
    case 'price-high-to-low':
      return 'Price: High - Low';
    case 'name-a-to-z':
      return 'Product Name: A - Z';
    case 'name-z-to-a':
      return 'Product Name: Z - A';
    case 'product-num-low-to-high':
      return 'Product #: Low - High';
    case 'product-num-high-to-low':
      return 'Product #: High - Low';
    case 'size-sm-to-lg':
      return 'Size: Small - Large';
    case 'size-lg-to-sm':
      return 'Size: Large - Small';
    case 'most-popular':
    default:
      return 'Most Popular';
  }
}

/**
 * Modifies URLs in style attributes to be absolute path (based on: window.location.href)
 *
 * @param {any} element
 * @param {string} querySelector
 */
export function modifyStyleURL(
  element: any,
  querySelector: string = 'polygon[style*="url("], path[style*="url("]'
) {
  const REGX_SVG_URL = /(http|https):\/\/([-a-zA-Z0-9@:%._\+~#=]{2,256}\.)?avery\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)#/g;

  element.querySelectorAll(querySelector).forEach((el) => {
    el.setAttribute('style', el.getAttribute('style').replace(REGX_SVG_URL, '#'));
    el.setAttribute(
      'style',
      el.getAttribute('style').replace('(#', '(' + window.location.href + '#')
    );
  });
}
