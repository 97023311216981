import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  ViewEncapsulation,
} from '@angular/core';
import { EMPTY, Subject, Observable, BehaviorSubject } from 'rxjs';
import { VisibilityResult } from './directives/observe-visibility/observe-visibility.directive';

@Component({
  selector: 'avery-cr-fold-aware-section',
  templateUrl: './fold-aware-section.component.html',
  styleUrls: ['./fold-aware-section.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FoldAwareSectionComponent implements AfterViewInit {
  render$: Observable<boolean> = EMPTY;
  private render: Subject<boolean> = new BehaviorSubject<boolean>(false);

  constructor() {}

  ngAfterViewInit(): void {
    this.render$ = this.render.asObservable();
  }

  isVisible({ intersecting }: VisibilityResult) {
    if (intersecting) {
      this.render.next(intersecting);
    }
  }
}
