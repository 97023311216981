import { combineReducers } from '@ngrx/store';

import {
  Action,
  APPLY_TEMPLATE_FILTER,
  GET_TEMPLATE_FILTER,
  PARAM_TEMPLATE_FILTERS,
  PARAM_TEMPLATE_TOGGLES,
  REMOVE_TEMPLATE_FILTER,
  RESET_TEMPLATE_FILTERS,
  RETRIEVE_CATEGORY_TEMPLATES,
  RETRIEVE_ALL_TEMPLATES,
  SELECT_TEMPLATE,
  RESET_TEMPLATE,
  TOGGLE_TEMPLATE_FILTER_OFF,
  TOGGLE_TEMPLATE_FILTER_ON,
  UPDATE_TEMPLATE_SORT
} from '../actions';

export function templateSearchReducer(state = [], action: Action) {
  switch (action.type) {
    case RETRIEVE_ALL_TEMPLATES:
      return action.payload.templates;
    default:
      return state;
  }
}

const filterState = {
  categoryTemplates: [],
  filters: [],
  selectedFilters: {},
  selectedToggles: [],
  sort: '',
};

export function templateFilterReducer(state = filterState, action: Action) {
  switch (action.type) {
    case RETRIEVE_CATEGORY_TEMPLATES:
      return Object.assign({}, state, { categoryTemplates: action.payload.templates });
    case GET_TEMPLATE_FILTER:
      return Object.assign({}, state, { filters: action.payload.filters });
    case TOGGLE_TEMPLATE_FILTER_ON: {
      const selectedToggles = state.selectedToggles.concat(action.payload.filter);
      return Object.assign({}, state, { selectedToggles });
    }
    case TOGGLE_TEMPLATE_FILTER_OFF: {
      const selectedToggles = state.selectedToggles.filter(toggle => toggle !== action.payload.filter);
      return Object.assign({}, state, { selectedToggles });
    }
    case APPLY_TEMPLATE_FILTER: {
      const selectedFilters = Object.assign({}, state.selectedFilters, action.payload.filter);
      return Object.assign({}, state, { selectedFilters });
    }
    case REMOVE_TEMPLATE_FILTER:
      return Object.assign({}, state, { selectedFilters: action.payload.selectedFilters });
    case PARAM_TEMPLATE_FILTERS:
      return Object.assign({}, state, { selectedFilters: action.payload.filters });
    case PARAM_TEMPLATE_TOGGLES:
      return Object.assign({}, state, { selectedToggles: action.payload.toggleFilters });
    case RESET_TEMPLATE_FILTERS:
      return Object.assign({}, state, { selectedFilters: {}, selectedToggles: [] });
    case UPDATE_TEMPLATE_SORT:
      return Object.assign({}, state, { sort: action.payload.sort });
    default:
      return state;
  }
}

export function selectedTemplateReducer(state = {}, action: Action) {
  switch (action.type) {
    case SELECT_TEMPLATE:
      return action.payload.template;
    case RESET_TEMPLATE:
      return {};
    default:
      return state;
  }
}

export const templateReducers = combineReducers({
  totalTemplates: templateSearchReducer,
  filter: templateFilterReducer,
  detail: selectedTemplateReducer,
});
