import {combineReducers } from '@ngrx/store';
import {File, Project, Stencil} from '../../app/shared/models';
import {
  Action,
  COPY_PROJECT, COPY_STENCIL, DELETE_FILES,
  DELETE_PROJECT, DELETE_STENCIL,
  DISPLAY_MORE_ITEMS, INIT_FILE_LIST, INIT_PROJECT_LIST,
  INIT_STENCIL_LIST,
  RESET_MY_ACCOUNT, SET_ITEM_TOTAL, SET_SORT_TYPE, UPDATE_FILES,
  UPDATE_PROJECT, UPDATE_STENCIL
} from '../actions';

const INIT_MYACCOUNT = {
  sortType: 'Newest',
  total: 0,
  displayAmt: 12
};

export function MyAccountFilterReducer(state = INIT_MYACCOUNT, action: Action) {
  switch (action.type) {
    case RESET_MY_ACCOUNT:
      return INIT_MYACCOUNT;
    case DISPLAY_MORE_ITEMS:
      return {
        ...state,
        displayAmt: action.payload
      };
    case SET_ITEM_TOTAL:
      return {
        ...state,
        total: action.payload
      };
    case SET_SORT_TYPE:
      return {
        ...state,
        sortType: action.payload
      };
    default:
      return state;
  }
}

export function ProjectsReducer(state = [], action: Action) {
  switch (action.type) {
    case INIT_PROJECT_LIST:
      return action.payload.projects;
    case COPY_PROJECT:
      return [action.payload.projectAdd, ...state];

    case UPDATE_PROJECT:
      return [...state.map((project: Project) => {
        if (project.id === action.payload.projectAdd.id) {
          return action.payload.projectAdd;
        }
        return project;
      })];
    case DELETE_PROJECT:
      return state.filter((project: Project) => action.payload.deleteId !== project.id);
    default:
      return state;
  }
}

export function StencilReducer(state = [], action: Action) {
    switch (action.type) {
    case INIT_STENCIL_LIST:
      return action.payload.stencils;
    case COPY_STENCIL:
      return [action.payload.stencilAdd, ...state];
    case UPDATE_STENCIL:
      return [...state.map((stencil: Stencil) => {
        if (stencil.id === action.payload.stencilAdd.id) {
          return {...stencil, ...action.payload.stencilAdd};
        }
        return stencil;
      })];
    case DELETE_STENCIL:
      return state.filter((stencil: Stencil) => action.payload.id = stencil.id);
    default:
      return state;
  }
}

export function FilesReducer(state = [], action: Action) {
  switch (action.type) {
    case INIT_FILE_LIST:
      return action.payload.files;

    case UPDATE_FILES:
      const files = state.concat(action.payload.fileAdd)
        .filter((elem: File) => {
          if (action.payload.deleteId !== elem.id) {
            return elem;
          }
        });
      return files;
    case DELETE_FILES:
      return state.filter((file: File) => action.payload.deleteId !== file.id);
    default:
      return state;
  }
}

export const myAccountReducer = combineReducers({
  filter: MyAccountFilterReducer,
  files: FilesReducer,
  projects: ProjectsReducer,
  stencils: StencilReducer,
});
