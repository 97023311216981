export interface Profile {
  email: string;
  firstName: string;
  lastName: string;
  optIn: boolean;
}

export type MessageType =
  | 'magento_newsletter'
  | 'exclude_cart'
  | 'exclude_welcome'
  | 'industrial'
  | 'exclude_welcome_industrial'
  | 'promotions';

export interface ProfileCordialStatus {
  email: string;
  messageTypes: MessageType[];
  optIn: boolean;
}

export interface UpdateCordialStatusRes {
  consumerId: string;
  email: string;
  sourceId: string;
  locale: string;
  firstName: string;
  lastName: string;
  isDisabled: boolean;
  country: {
    countryCode: string;
    countryName: string;
    nativeName: string;
  };
  createDate: string;
  modifiedDate: string;
  passwordReset: boolean;
  isConsumer: boolean;
  optIn: boolean;
  messageTypes: {
    promotions: boolean;
    industrial: boolean;
  };
}

// Used in ngrx store
export interface ProfileState {
  firstName: string;
  lastName: string;
  email: string;
  newEmail: string;
  updateStatus: boolean;
  profileData: Profile;
}

// Used in My Account Profile Page sub-menu
export interface SectionMenu {
  id: string;
  title: string;
  top: number;
  bottom: number;
}
export interface EmailForm {
  newEmail: string;
  password: string;
}
