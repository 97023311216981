import { Product } from './product.interface';
export interface FilterState {
  filters: object;
  products: Product[];
  productFilters: object;
  selectedFilters: object;
  toggledFilters: any[];
  sort: string;
}

export interface FilterAcg {
  name: string;
  title: string;
  body: string;
  value: string;
}
