// Incoming interface from dotcms graphql query. Creating generic to allow dotcms strings vs final

import { TrackByFunction } from '@angular/core';

// product. TODO: Replace Product with (Product<'untyped'> vs Product<'typed'>)
export interface GQLProduct<T extends 'untyped' | 'typed'> {
  blanklabel?: RelatedContent;
  crossSellMapping?: { key: string; value: string }[];
  difyProductNumber?: T extends 'untyped' ? string : number;
  displayXSell?: T extends 'untyped' ? string : boolean;
  configType: string;
  discontinued: T extends 'untyped' ? string : boolean;
  discontinuedDate: Date;
  ecommerceEnabled: T extends 'untyped' ? string : number;
  comingSoon: T extends 'untyped' ? string : boolean;
  ecommercePrice: T extends 'untyped' ? string : number;
  featuresAndBenefits: string;
  itemNumber: string;
  material?: string;
  mediagroups?: T extends 'untyped' ? { media: Media[] }[] : null;
  metaDescription: string;
  metaTitle: string;
  msrpPrice: T extends 'untyped' ? string : number;
  packSize?: number;
  shape?: string;
  size?: string;
  sureFeed?: T extends 'untyped' ? string : boolean;
  productCategory: {
    name: string;
  }[];
  productGroup?: { items?: ProductGroupItem[] };
  productDisplayName: string;
  productNumber: string;
  productSummary: string;
  sheetSize?: string;
  shortDescription: string;
  stockNumber: string;
  temporarilyUnavailable: T extends 'untyped' ? string : boolean;
  ultrahold?: T extends 'untyped' ? string : boolean;
  upcNumber: string;
  webUrl: string;
}

export interface Media {
  key: string;
  value: string;
}

export interface RelatedContent {
  configType: string;
  defaultMediaUrl: string;
  labelCategory: string;
  productNumber: string;
  size: string;
}

export interface ProductGroupItem {
  ecommercePrice: string;
  msrpPrice: string;
  option: any;
  packSize?: number;
  popularity?: number;
  setsPerPack?: string;
  size?: string;
  stockNumber: any;
}

export interface Product {
  adhesive?: string;
  blanklabel?: RelatedContent;
  color?: string;
  configType: string;
  crossSellMapping?: { key: string; value: string }[];
  defaultMediaUrl: string;
  difyProductNumber?: number;
  discontinued: boolean;
  discontinuedDate?: Date;
  displayXSell?: boolean;
  ecommerceEnabled: boolean;
  comingSoon: boolean;
  ecommercePrice: number;
  featuresAndBenefits: string;
  identifier: string; // TODO: Remove identifier (used in ind PDP and product locator page)
  inode: TrackByFunction<Product>; // TODO: Remove inode (currently used in PFP, but can change to upcNumber)
  itemNumber: number;
  material?: string;
  media: Media[];
  metaDescription?: string;
  metaTitle?: string;
  msrpPrice?: number;
  packSize?: number;
  packSizeFilter?: number;
  popularity: number;
  printerType?: string;
  printtotheedge?: boolean;
  productCategory: string;
  productDisplayName: string;
  productGroup?: ProductGroupItem[];
  productNumber: number;
  productShortDisplayName: string;
  productSummary: string;
  shape?: string;
  sheetSize?: string;
  shortDescription: string;
  shortProductSummary: string;
  size?: string;
  // sizeOz?: string;
  // sizeRange?: string;
  sortOrder: number;
  stockNumber: number;
  temporarilyUnavailable: boolean;
  upcNumber: number;
  usage?: string;
  webUrl?: string;
  // whereToBuyEnabled: boolean;
  width?: number;
}

export interface ProductImage {
  alt: string;
  poster?: string;
  preview_src?: string;
  src?: string;
  youtubeID?: string;
}

export interface ProductState {
  selectedProduct: Product;
  productImages: ProductImage[];
  selectedImage: ProductImage;
  productGroup: ProductGroupItem[];
}

export interface GoogleStructuredData {
  name: string;
  description: string;
  image: string;
  sku: number;
  price: number;
  url: string;
}

export interface ProductFilterSection {
  component?: string;
  display: string;
  expanded: boolean;
  filters: string[];
  value: string;
}
