export interface CcpaDownloadData {
  address?: string;
  billing_company?: string;
  billing_first_name?: string;
  billing_last_name?: string;
  cart_email?: string;
  cart_first_name?: string;
  cart_last_name?: string;
  city?: string;
  company_name?: string;
  consumer_id: string;
  country?: string;
  email: string;
  phone?: string;
  registered_first_name?: string;
  registered_last_name?: string;
  state?: string;
  updated_at?: string;
  uuid: string;
  zip_code?: string;
}
