import { InputBase } from './input-base';

export class TextInput extends InputBase<string>{
  controlType = 'text';
  type: string;

  constructor(options: object = {}) {
    super(options);
    this.type = options['type'] || '';
  }
}
