import { combineReducers } from '@ngrx/store';

import { FilterState } from '../../app/shared/models/interfaces';
import {
  Action,
  APPLY_FILTER,
  CHANGE_FILTERS,
  GET_PRODUCTS,
  PARAM_FILTERS,
  PARAM_TOGGLES,
  REMOVE_FILTER,
  REPLACE_FILTER_HEADER,
  RESET_FILTERS,
  RESET_TOGGLES,
  RETRIEVE_FILTERS,
  SELECT_PRODUCT_FILTERS,
  TOGGLE_FILTER_OFF,
  RESET_PRODUCT_STATE,
  TOGGLE_FILTER_ON,
  UPDATE_SORT,
  RESET_SORT,
} from '../actions';

export function FilterOptionsReducer(state = [], action: Action) {
  switch (action.type) {
    case RETRIEVE_FILTERS:
      return action.payload.filters;
    default:
      return state;
  }
}

export function ProductFiltersReducer(state = {}, action: Action) {
  switch (action.type) {
    case SELECT_PRODUCT_FILTERS:
      return action.payload.filter;
    case CHANGE_FILTERS:
      return Object.assign({}, state, { filters: action.payload.filters });
    case REPLACE_FILTER_HEADER:
      return Object.assign({}, state, { title: action.payload.productName });
    case RESET_PRODUCT_STATE:
      return {};
    default:
      return state;
  }
}

export function ProductsReducer(state = [], action: Action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return action.payload.products;
    case RESET_PRODUCT_STATE:
      return [];
    default:
      return state;
  }
}

export function SelectedFiltersReducer(state = {}, action: Action) {
  switch (action.type) {
    case APPLY_FILTER:
      return Object.assign({}, state, action.payload.filter);
    case REMOVE_FILTER:
      return Object.assign({}, state, action.payload.selectedFilters);
    case RESET_PRODUCT_STATE:
    case RESET_FILTERS:
      return {};
    case PARAM_FILTERS:
      return action.payload.filters;
    default:
      return state;
  }
}

export function ToggledFiltersReducer(state = [], action: Action) {
  switch (action.type) {
    case TOGGLE_FILTER_ON:
      return state.concat(action.payload.filter);
    case TOGGLE_FILTER_OFF:
      return state.filter((option) => option !== action.payload.filter);
    case PARAM_TOGGLES:
      return action.payload.toggleFilters;
    case RESET_TOGGLES:
      return [];
    default:
      return state;
  }
}

export function UpdateSortReducer(state = '', action: Action) {
  switch (action.type) {
    case UPDATE_SORT:
      return action.payload.sort;
    case RESET_SORT:
      return '';
    default:
      return state;
  }
}

export const filterReducers = combineReducers({
  filters: FilterOptionsReducer,
  products: ProductsReducer,
  productFilters: ProductFiltersReducer,
  selectedFilters: SelectedFiltersReducer,
  toggledFilters: ToggledFiltersReducer,
  sort: UpdateSortReducer,
});
