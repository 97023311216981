import { combineReducers } from '@ngrx/store';
import { TransparencyQuoteState, TransSelectionStateInterface } from '../../app/shared/models';

import {
  Action,
  TRANS_RESET_PRICE,
  TRANS_RESET_SELECTION,
  TRANS_SELECT_SIZE,
  TRANS_SELECTIONS_VISIBILITY,
  TRANS_UPDATE_UNWIND,
  TRANS_UPDATE_HANDAPPLIED,
  TRANS_UPDATE_PRICE,
  TRANS_UPDATE_QTY,
  TRANS_UPDATE_SELECTION,
  TRANS_UPDATE_UNDERPRINT,
} from '../actions';

const INIT_TRANSPARENCY_PRICE: TransparencyQuoteState = {
  pricePerLabel: 0,
  subTotal: 0,
};
const INIT_TRANSPARENCY_SELECT: TransSelectionStateInterface = {
  custom: null,
  customShape: null,
  size: null,
  material: null,
  unwindId: 0,
  hand_applied: 1,
  hideSelections: true,
  labelQty: 0,
  fileName: '',
  shape: '',
  designs: 1,
  finish: null,
  underPrint: 'none',
};

export function TransparencySelectionReducer(state = INIT_TRANSPARENCY_SELECT, action: Action) {
  switch (action.type) {
    case TRANS_UPDATE_SELECTION:
      return { ...state, ...action.payload };
    case TRANS_RESET_SELECTION:
      return INIT_TRANSPARENCY_SELECT;
    case TRANS_SELECT_SIZE:
      return { ...state, size: action.payload };
    case TRANS_UPDATE_UNWIND:
      return { ...state, unwindId: action.payload };
    case TRANS_UPDATE_HANDAPPLIED:
      return { ...state, hand_applied: action.payload };
    case TRANS_UPDATE_UNDERPRINT:
      return { ...state, underPrint: action.payload };
    case TRANS_UPDATE_QTY:
      return { ...state, labelQty: action.payload };
    case TRANS_SELECTIONS_VISIBILITY:
      return { ...state, hideSelections: action.payload };
    default:
      return state;
  }
}

export function GetTransparencyStateReducer(state = INIT_TRANSPARENCY_PRICE, action: Action) {
  switch (action.type) {
    case TRANS_UPDATE_PRICE:
      return { ...state, ...action.payload };
    case TRANS_RESET_PRICE:
      return INIT_TRANSPARENCY_PRICE;
    default:
      return state;
  }
}

export const transparencyReducers = combineReducers({
  selection: TransparencySelectionReducer,
  prices: GetTransparencyStateReducer,
});
