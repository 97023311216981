/**
 * Below are the interfaces for rolls quote stuff. They can and should be utilized in the calculator/roll pages. Refer
 * to the Magneto Roll Labels Web Services wiki for more info
 */

export interface VersionOption {
  id: number;
  qty: number;
  name?: string;
  artwork_id?: string;
  artwork_status?: string;
  check_design?: string;
  asset_id?: string;
  project_stencil_id?: string;
  transparency_brand_id?: string;
  transparency_first_code?: string;
  transparency_last_code?: string;
  check_design_reason?: string;
}

// * === Changed for TransparencyQuoteRequest because those attributes are not required
export interface RollsQuoteRequest {
  product?: number;
  size_id?: number;
  shape_id?: number;
  label_width?: number;
  label_height?: number;
  label_corner_radius?: number;
  square_corner?: number;
  label_quantity: number;
  core_product?: number;
  material: number;
  finish_type?: number; // *
  finish_texture?: number; // *
  color?: number; // *
  underprint?: string; // *
  artwork_source: string;
  artwork_project_id?: string;
  avery_quote_type?: string;
  special_handling?: string;
  total_versions: number;
  version_options: VersionOption[];
  hand_applied: number;
  unwind_id: number;
  unwind_pending?: number;
  proof_type: string;
  customer_notes?: string;
  consumer_id?: string;
  buy_more_quantities?: string; // *
  design_in?: string;
}

export interface BundleOption {
  id: number;
  name: string;
  selection_id: number;
  qty: number;
  product: string;
}

export interface RollsBuyMoreEstimate {
  price: number;
  label_quantity: number;
  unit_price: number;
}

export interface RollsQuoteResponse extends RollsQuoteRequest {
  label_across: number;
  label_around: number;
  labels_across_per_frame: number;
  labels_around_per_frame: number;
  labels_per_frame: number;
  frame_count: number;
  frame_lead: number;
  frame_repeat: number;
  frame_run_length: number;
  run_length: number;
  run_length_charge: number;
  calculator: {
    config: {
      frame_max_across: number;
      frame_max_around: number;
      lead_frame: number;
      lead_frame_per_version: number;
      lead_in_frame_per_version: number;
      lead_out_frame_per_version: number;
      gap_across: number;
      gap_around: number;
      web_across: number;
    };
    request: {
      label_quantity: number;
      unwind_id: number;
      hand_applied: number;
      unwind_pending: number;
      total_versions: number;
      label_width: number;
      label_height: number;
    };
  };
  material_original_sku: string;
  material_original_attributes: {
    material: string;
    finish_type: string;
    finish_texture: string;
    color: string;
  };
  original_qty: number;
  qty: number;
  price: number;
  dpo_sku: string;
  dpo_product_group: string;
  unit_price: number;
  bundle_options: BundleOption[];
  buy_more_estimates: RollsBuyMoreEstimate[];
}

export interface TransparentQuoteRequest extends RollsQuoteRequest {
  transparency_enabled: number;
  origin: string;
}

export interface TransparentQuoteResponse extends RollsQuoteResponse {
  transparency_enabled: number;
  origin: string;
  configuration_code: string;
  die_id: number;
  reprintable: boolean;
}
