import isEmpty from 'lodash-es/isEmpty';

/**
 * Function that returns an object after taking out empty properties. Used to reduce filter
 * cluttering in the URL
 *
 * @param object
 */
export function removeEmptyProperties(object) {
  const newObject = object;
  const keys = Object.keys(newObject);

  keys.forEach((key) => {
    if (isEmpty(newObject[key])) {
      delete newObject[key];
    }
  });

  return newObject;
}
