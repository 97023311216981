import { ItemsPrice } from './weprint-calculator.interface';
import { FormatSize } from './weprint-calculator-config.interface';

export interface SheetsSize extends FormatSize {
  isCustomSize?: number;
  die_id: number;
  size: string;
  oneUpImage: string;
  materials: SheetsMaterial[];
  productShape: string;
  productGroupName: string;
  sheetImage: string;
  imageUrl?: string;
  labelDescription: string;
}

export interface SheetsMaterial {
  addWhiteColor?: boolean;
  includeWhiteUnderprint: boolean;
  features: any[];
  itemsPriceList: ItemsPrice[];
  material: string;
  materialId: number;
  simpleSku: number;
}

export interface SheetShapes {
  arched?: SheetsSize[];
  cd_dvd?: SheetsSize[];
  ellipse?: SheetsSize[];
  heart?: SheetsSize[];
  scallop_ellipse?: SheetsSize[];
  rect?: SheetsSize[];
  scallop_rect?: SheetsSize[];
  square?: SheetsSize[];
  scallop_square?: SheetsSize[];
  circle?: SheetsSize[];
  scallop_circle?: SheetsSize[];
  stars?: SheetsSize[];
  default: SheetsSize[];
  scallop: SheetsSize[];
}

// Magento returns an array of these
export interface SheetsConfig {
  shapes: SheetShapes;
  keyword: string;
}
