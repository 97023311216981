import { combineReducers } from '@ngrx/store';

import {
  Action,
  ROLL_UPDATE_ALL,
  ROLL_CREATE_ARTWORKS,
  ROLL_UPDATE_ARTWORKS_IMAGE,
  ROLL_UPDATE_ESTIMATE,
  ROLL_UPDATE_SELECTION,
  REMOVE_ONE_ARTWORK,
  ROLL_UPDATE_ARTWORKS,
  ROLL_UPDATE_ARTWORKS_QTY,
  RESET_ROLL_ARTWORK,
  RESET_ROLL_SELECT,
  ROLLS_UPDATE_UNWIND,
  ROLLS_UPDATE_PROOF_TYPE,
  ROLLS_UPDATE_UNDERPRINT,
  ROLLS_UPDATE_HANDAPPLIED,
  ROLL_ADD_ARTWORK,
  REORDER_CREATE_LIST,
} from '../actions';
// TODO: Fix the roll state interface
import {
  RollStateInterface,
  RollsSelectionInterface,
  RollsSelectionStateInterface,
  ArtworksInterface,
  RollsQuoteResponse,
  ReorderStateInterface,
} from '../../app/shared/models';

// TODO: Need to simplify the state
const INIT_ROLL_REORDER: ReorderStateInterface = {
  list: [],
  selected: [],
};
const INIT_ARTWORK: Array<ArtworksInterface> = [];
const INIT_ROLL_SELECTION: RollsSelectionStateInterface = {
  dpoProductGroup: '',
  dpoSku: '',
  estimateSelected: null,
  format: 'rolls',
  shape: '',
  unitType: 'fraction',
  custom: {
    selected: false,
    rectangle: {
      height: null,
      width: null,
    },
    round: {
      diameter: null,
    },
    oval: {
      height: null,
      width: null,
    },
    square: {
      height: null,
      width: null,
    },
    squareCorners: false,
  },
  customShape: {
    selected: false,
    status: 'off',
  },
  size: null,
  material: null,
  finish: null,
  finishType: null,
  proofType: '',
  designs: 0,
  labelQty: 0,
  underPrint: 'none',
  unwindId: 0,
  hand_applied: 1,
};

export function RollArtworks(state = INIT_ARTWORK, action: Action) {
  switch (action.type) {
    case ROLL_CREATE_ARTWORKS:
      return [...action.payload];
    case ROLL_ADD_ARTWORK:
      return [...state, action.payload];
    case ROLL_UPDATE_ARTWORKS_QTY:
      return state.map((data, index) => {
        return { ...data, qty: action.payload.aryQty[index] };
      });
    case RESET_ROLL_ARTWORK:
      return [];
    case ROLL_UPDATE_ARTWORKS:
      return state.map((data, index) => {
        if (index === action.payload.index) {
          return { ...data, ...action.payload.data };
        } else {
          return data;
        }
      });
    case REMOVE_ONE_ARTWORK:
      return state.filter((data, index) => {
        return index !== action.payload.index;
      });
    default:
      return state;
  }
}

export function RollSelectionReducer(state = INIT_ROLL_SELECTION, action) {
  switch (action.type) {
    case ROLL_UPDATE_SELECTION:
      return {
        ...state,
        ...action.payload,
      };
    case ROLL_UPDATE_ESTIMATE:
      return { ...state, estimateSelected: action.payload };
    case RESET_ROLL_SELECT:
      return INIT_ROLL_SELECTION;
    case ROLLS_UPDATE_UNWIND:
      return { ...state, unwindId: action.payload };
    case ROLLS_UPDATE_PROOF_TYPE:
      return { ...state, proofType: action.payload };
    case ROLLS_UPDATE_UNDERPRINT:
      return { ...state, underPrint: action.payload };
    case ROLLS_UPDATE_HANDAPPLIED:
      return { ...state, hand_applied: action.payload };
    default:
      return state;
  }
}

export function RollReorderReducer(state = INIT_ROLL_REORDER, action) {
  switch (action.type) {
    case REORDER_CREATE_LIST:
      return { ...state, list: [...action.payload] };
    default:
      return state;
  }
}

export const rollReducers = combineReducers({
  artworks: RollArtworks,
  rollReorder: RollReorderReducer,
  rollSelection: RollSelectionReducer,
});
