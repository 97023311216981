import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AcgComponent } from './acg.component';
import { AppService } from 'app/app.service';

@NgModule({
  declarations: [AcgComponent],
  imports: [CommonModule],
  exports: [AcgComponent],
  providers: [AppService],
})
export class AcgModule {}
