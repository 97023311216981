import { Component, Input, OnChanges, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import isEmpty from 'lodash-es/isEmpty';

import { NavigationService } from '../../../../navigation/nav-shared/navigation.service';

import { environment } from '../../../../environments/environment';
import { NotFoundError } from '../../models/index';

@Component({
  selector: 'app-error',
  templateUrl: './error.component.html',
})
export class ErrorComponent implements OnChanges, OnInit {
  @Input() mainMessage;
  @Input() errorMessage = this.sanitizer.bypassSecurityTrustHtml(`
    Error Code: <span class="error-code">404</span>
  `);
  environment: any = environment;
  subscriptions: Subscription[];
  themeModePerPath: string;
  themeMode: string = 'default';

  constructor(
    private navigationService: NavigationService,
    private router: Router,
    private sanitizer: DomSanitizer
  ) {}

  ngOnChanges() {
    const defaultError = new NotFoundError();

    if (isEmpty(this.mainMessage)) {
      this.mainMessage = defaultError.mainMessage;
    }

    if (isEmpty(this.errorMessage)) {
      this.errorMessage = this.sanitizer.bypassSecurityTrustHtml(defaultError.errorMessage);
    }
  }

  ngOnInit() {
    this.subscriptions = [this.averyThemeModeSubscription()];

    this.themeModePerPath =
      window.location.pathname.indexOf('industrial') > -1 ? 'industrial' : 'default';

    // Default Main Messages based on Industrial and Default Mode
    if (!this.mainMessage) {
      this.mainMessage =
        this.themeMode === 'industrial'
          ? "The page you want either doesn't exist or no longer exists."
          : "It looks like the page you want doesn't exist.";
    }

    // Emit GA event
    (<any>window).dataLayer.push({
      event: 'Page not found',
      url: window.location.href,
    });
  }

  /**
   * Subscription to get the latest themeMode data
   *
   * @returns {Subscription}
   * @memberof ErrorComponent
   */
  averyThemeModeSubscription(): Subscription {
    return this.navigationService.themeMode.subscribe((type: string) => (this.themeMode = type));
  }

  /**
   * Direct user to search page with the search term in the query parameter
   *
   * @param {string} searchTerm
   * @memberof ErrorComponent
   */
  submitSearch(searchTerm: string) {
    if (!isEmpty(searchTerm)) {
      this.router.navigate(['/srch'], { queryParams: { q: searchTerm } });
    }
  }
}
