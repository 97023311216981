export type PaymentPeriod = 'monthly' | 'annual';
export interface ClientTokenRes {
  clientToken: string;
}

interface IResponse {
  errors?: any;
  message?: string;
  params?: any;
  success: boolean;
}

export interface CustomerRes extends IResponse {
  customer: Customer;
  verification?: Verification;
}

export interface PlanRes extends IResponse {
  plans: Plan[];
}

export interface FormCountryRes {
  CAN: FormCountry;
  USA: FormCountry;
}

export interface FormCountry {
  name: string;
  states: FormState[];
}

export interface FormState {
  name: string;
  abbreviation: string;
}

export interface SubscriptionRes extends IResponse {
  subscription: Subscription;
}

export interface ChangeSubscriptionRes<T extends 'pending' | 'active'> {
  canceledSubscription: SubscriptionRes;
  pendingSubscription: T extends 'pending' ? SubscriptionRes : null;
  activeSubscription: T extends 'active' ? SubscriptionRes : null;
}

export interface ClientTokenCache {
  clientToken: string;
  expirationDate: Date;
}

export interface SubscriptionPlan {
  id: string;
  merchantId?: string;
  billingDayOfMonth: any;
  billingFrequency: number;
  currencyIsoCode: string;
  description: string;
  name: string;
  numberOfBillingCycles: any;
  trialDuration: number;
  trialDurationUnit: string;
  trialPeriod: boolean;
  createdAt: string;
  updatedAt: string;
  paymentPeriod: PaymentPeriod;
  price: number;
  monthlyPrice: number;
}
export interface SubscriptionPlanResponse {
  plans: SubscriptionPlan[];
  success: boolean;
}

export interface Customer {
  id: string;
  merchantId: string;
  firstName: string;
  lastName: string;
  company: string;
  email: string;
  phone: string;
  fax: string;
  website: string;
  createdAt: string;
  updatedAt: string;
  customFields: string;
  globalId: string;
  graphQLId: string;
  creditCards: CreditCard[];
  addresses: Address[];
  paymentMethods: PaymentMethod[];
}

export interface CreditCard {
  accountType?: any;
  billingAddress: BillingAddress;
  bin: string;
  cardType: string;
  cardholderName: string;
  commercial: string;
  countryOfIssuance: string;
  createdAt: string;
  customerId: string;
  customerGlobalId: string;
  customerLocation: string;
  debit: string;
  default: boolean;
  durbinRegulated: string;
  expirationDate: string;
  expirationMonth: string;
  expirationYear: string;
  expired: boolean;
  globalId: string;
  graphQLId: string;
  healthcare: string;
  imageUrl: string;
  issuingBank: string;
  last4: string;
  maskedNumber: string;
  payroll: string;
  prepaid: string;
  productId: string;
  subscriptions: Subscription[];
  token: string;
  uniqueNumberIdentifier: string;
  updatedAt: string;
  venmoSdk: boolean;
  verifications: Verification[];
}

export interface BillingAddress {
  id: string;
  customerId: string;
  firstName: string;
  lastName: string;
  company: string;
  streetAddress: string;
  extendedAddress: string;
  locality: string;
  region: string;
  postalCode: string;
  countryCodeAlpha2: any;
  countryCodeAlpha3: any;
  countryCodeNumeric: any;
  countryName: any;
  createdAt: string;
  updatedAt: string;
}

export interface Address {
  id: string;
  customerId: string;
  firstName: any;
  lastName: any;
  company: any;
  streetAddress: any;
  extendedAddress: any;
  locality: any;
  region: any;
  postalCode: string;
  countryCodeAlpha2: any;
  countryCodeAlpha3: any;
  countryCodeNumeric: any;
  countryName: any;
  createdAt: string;
  updatedAt: string;
}

export interface PaymentMethod {
  billingAddress: BillingAddress;
  bin: string;
  cardType: string;
  cardholderName: any;
  commercial: string;
  countryOfIssuance: string;
  createdAt: string;
  customerId: string;
  customerGlobalId: string;
  customerLocation: string;
  debit: string;
  default: boolean;
  durbinRegulated: string;
  expirationMonth: string;
  expirationYear: string;
  expired: boolean;
  globalId: string;
  graphQLId: string;
  healthcare: string;
  imageUrl: string;
  issuingBank: string;
  last4: string;
  payroll: string;
  prepaid: string;
  productId: string;
  subscriptions: Subscription[];
  token: string;
  uniqueNumberIdentifier: string;
  updatedAt: string;
  venmoSdk: boolean;
  verifications: any[];
  maskedNumber: string;
  expirationDate: string;
}

export interface Plan {
  id: string;
  merchantId: string;
  billingDayOfMonth: any;
  billingFrequency: number;
  currencyIsoCode: string;
  description: string;
  name: string;
  numberOfBillingCycles: number;
  price: string;
  trialDuration: number;
  trialDurationUnit: string;
  trialPeriod: boolean;
  createdAt: string;
  updatedAt: string;
  addOns: any[];
  discounts: any[];
}

export interface Subscription {
  addOns: any[];
  balance: string;
  billingDayOfMonth: number;
  billingPeriodEndDate: string;
  billingPeriodStartDate: string;
  createdAt: string;
  updatedAt: string;
  currentBillingCycle: number;
  daysPastDue: any;
  discounts: any[];
  failureCount: number;
  firstBillingDate: string;
  id: string;
  merchantAccountId: string;
  neverExpires: boolean;
  nextBillAmount: string;
  nextBillingPeriodAmount: string;
  nextBillingDate: string;
  numberOfBillingCycles: number;
  paidThroughDate: any;
  paymentMethodToken: string;
  planId: string;
  price: string;
  status: string;
  trialDuration: number;
  trialDurationUnit: string;
  trialPeriod: boolean;
  descriptor: Descriptor;
  description: any;
  transactions: Transaction[];
  statusHistory: {
    timestamp: string;
    status: string;
    user: string;
    subscriptionSource: string;
    balance: string;
    price: string;
    currencyIsoCode: string;
    planId: string;
  }[];
}

export interface Descriptor {
  name: any;
  phone: any;
  url: any;
}

export interface Verification {
  status: string;
  cvvResponseCode: string;
  avsErrorResponseCode: any;
  avsPostalCodeResponseCode: string;
  avsStreetAddressResponseCode: string;
  gatewayRejectionReason: any;
  merchantAccountId: string;
  processorResponseCode: string;
  processorResponseText: string;
  amount: string;
  currencyIsoCode: string;
  processorResponseType: string;
  threeDSecureInfo: any;
  id: string;
  billing: Partial<BillingAddress>;
  creditCard: Partial<CreditCard>;
}

export interface Transaction {
  id: string;
  status: string;
  type: string;
  currencyIsoCode: string;
  amount: string;
  merchantAccountId: string;
  subMerchantAccountId: any;
  masterMerchantAccountId: any;
  orderId: any;
  createdAt: string;
  updatedAt: string;
  customer: Partial<Customer>;
  billing: {
    id: string;
    firstName: string;
    lastName: string;
    company: any;
    streetAddress: string;
    extendedAddress: any;
    locality: string;
    region: string;
    postalCode: string;
    countryName: any;
    countryCodeAlpha2: any;
    countryCodeAlpha3: any;
    countryCodeNumeric: any;
  };
  refundId: any;
  refundIds: any[];
  refundedTransactionId: any;
  partialSettlementTransactionIds: any[];
  authorizedTransactionId: any;
  settlementBatchId: string;
  shipping: {
    id: any;
    firstName: any;
    lastName: any;
    company: any;
    streetAddress: any;
    extendedAddress: any;
    locality: any;
    region: any;
    postalCode: any;
    countryName: any;
    countryCodeAlpha2: any;
    countryCodeAlpha3: any;
    countryCodeNumeric: any;
  };
  customFields: string;
  avsErrorResponseCode: any;
  avsPostalCodeResponseCode: string;
  avsStreetAddressResponseCode: string;
  cvvResponseCode: string;
  gatewayRejectionReason: any;
  processorAuthorizationCode: string;
  processorResponseCode: string;
  processorResponseText: string;
  additionalProcessorResponse: any;
  voiceReferralNumber: any;
  purchaseOrderNumber: any;
  taxAmount: any;
  taxExempt: boolean;
  processedWithNetworkToken: boolean;
  creditCard: Partial<CreditCard>;
  statusHistory: {
    timestamp: string;
    status: string;
    amount: string;
    user?: string;
    transactionSource: string;
  }[];
  planId: string;
  subscriptionId: string;
  subscription: {
    billingPeriodEndDate: string;
    billingPeriodStartDate: string;
  };
  addOns: any[];
  discounts: any[];
  descriptor: Descriptor;
  recurring: boolean;
  channel: any;
  serviceFeeAmount: any;
  escrowStatus: any;
  disbursementDetails: {
    disbursementDate: string;
    settlementAmount: string;
    settlementCurrencyIsoCode: string;
    settlementCurrencyExchangeRate: string;
    fundsHeld: boolean;
    success: boolean;
  };
  disputes: any[];
  authorizationAdjustments: any[];
  paymentInstrumentType: string;
  processorSettlementResponseCode: string;
  processorSettlementResponseText: string;
  networkResponseCode: string;
  networkResponseText: string;
  threeDSecureInfo: any;
  shipsFromPostalCode: any;
  shippingAmount: any;
  discountAmount: any;
  networkTransactionId: string;
  processorResponseType: string;
  authorizationExpiresAt: string;
  refundGlobalIds: any[];
  partialSettlementTransactionGlobalIds: any[];
  refundedTransactionGlobalId: any;
  authorizedTransactionGlobalId: any;
  globalId: string;
  retryIds: any[];
  retriedTransactionId: any;
  retrievalReferenceNumber: string;
}

// TODO: Move to nav shared interfaces
export interface SSOProfile extends MinimumSSOProfile {
  userType: string;
  picUrl: string;
  provider: string;
  title: string;
  guid: string;
  UID: string;
  loginProviderUID: string;
  isSiteUID: boolean;
  UIDSignature: string;
  signatureTimestamp: string;
  loginProvider: string;
  isSiteUser: boolean;
  isTempUser: boolean;
  isConnected: boolean;
  nickname: string;
  photoURL: string;
  thumbnailURL: string;
  gender: string;
  age: number;
  birthDay: number;
  birthMonth: number;
  birthYear: number;
  country: string;
  state: string;
  city: string;
  zip: string;
  profileURL: string;
  iRank: number;
  proxiedEmail: string;
  languages: string;
  address: string;
  username: string;
  locale: string;
  verified: boolean;
  optIn: boolean;
  authorities: Authority[];
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  enabled: boolean;
}
export interface MinimumSSOProfile {
  consumerId: string;
  firstName: string;
  lastName: string;
  email: string;
}

interface Authority {
  role: string;
}

export interface CreditCardUpdateRequest {
  billingAddress?: {
    company?: string;
    countryCodeAlpha2?: string;
    countryCodeAlpha3?: string;
    countryCodeNumeric?: string;
    countryName?: string;
    extendedAddress?: string;
    firstName?: string;
    lastName?: string;
    locality?: string;
    postalCode?: string;
    region?: string;
    streetAddress?: string;
    options?: {
      updateExisting?: boolean;
    };
  };
  cardholderName?: string;
  cvv?: string;
  expirationDate?: string;
  expirationMonth?: string;
  expirationYear?: string;
  number?: string;
  options?: {
    failOnDuplicatePaymentMethod?: boolean;
    makeDefault?: boolean;
    skipAdvancedFraudChecking?: boolean;
    updateExistingToken?: string;
    verificationAmount?: string;
    verificationMerchantAccountId?: string;
    verifyCard?: boolean;
  };
}
