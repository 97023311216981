import { SafeHtml } from '@angular/platform-browser';

export interface Accordion {
  body: SafeHtml;
  id: string;
  title: string;
}

export interface CuralateConfiguration {
  containerID: string;
  filter: string;
  fullGalleryLink?: string;
}
