import { Directive, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { Subject } from 'rxjs';
import { filter, map, take } from 'rxjs/operators';

export type VisibilityResult = { intersecting: boolean; ratio: number };

@Directive({
  selector: '[averyCrObserveVisibility]',
})
export class ObserveVisibilityDirective {
  @Input() threshold: number = 0;
  @Input() rootMargin: number = 0;
  @Output() visible = new EventEmitter<VisibilityResult>();

  private subject$ = new Subject<{
    entry: IntersectionObserverEntry;
    observer: IntersectionObserver;
  }>();

  private observer: IntersectionObserver | undefined;

  constructor(private element: ElementRef) {}

  ngOnInit(): void {
    this.createObserver();
  }

  ngAfterViewInit(): void {
    this.startObservingElements();
  }

  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = undefined;
    }
    this.subject$.complete();
  }

  private createObserver() {
    const observerCallback = (
      entries: IntersectionObserverEntry[],
      observer: IntersectionObserver
    ) => {
      const isIntersecting = (_: IntersectionObserverEntry) =>
        _.isIntersecting || _.intersectionRatio > 0;
      entries.filter(isIntersecting).forEach((entry) => this.subject$.next({ entry, observer }));
    };
    const observerOptions = {
      rootMargin: `${this.rootMargin}px`,
      threshold: this.threshold,
    };
    this.observer = new IntersectionObserver(observerCallback, observerOptions);
  }

  private startObservingElements() {
    this.observer?.observe(this.element.nativeElement);
    this.subject$
      .pipe(
        //Destructured to only retrieve the properties we need
        map(({ entry: { isIntersecting: intersecting, intersectionRatio: ratio } }) => ({
          intersecting,
          ratio,
        })),
        //We only care about whether the item is visible
        filter((e) => e.intersecting),
        //We only want to know the first time it is
        take(1)
      )
      .subscribe((e: VisibilityResult) => {
        this.visible.emit(e);
      });
  }
}
