import { TrackByFunction } from '@angular/core';

export interface AveryTemplateCollection {
  averyTemplateCollection: GQLTemplate[];
}

// If using gql to get templates for filter page, then this can replace Template interface
export interface GQLTemplate {
  category: string[];
  cleanEdge: boolean;
  color: string;
  configType: string;
  description: string;
  difyProductNumber: number;
  image: string;
  isGhs: boolean;
  itemsPerSheet: number;
  metaDescription: string;
  metaTitle: string;
  printtotheedge: boolean;
  productCategoryType: string;
  seoDescription: string;
  shape: string;
  sheetSize: string;
  size: string;
  templateNumber: string;
  templateUri: string;
  trademark: string;
  trademarkTemplateNumber: string;
  type1: string;
  viewOrientation?: string;
}

export interface Template {
  averyContentType: string;
  category: string;
  cleanEdge?: boolean;
  color?: string;
  configType: string;
  description: string;
  difyProductNumber?: number;
  diyProductNumber?: number;
  height?: number;
  identifier: string;
  image: string;
  inode: TrackByFunction<Template>;
  isGhs?: boolean;
  itemNumber: number;
  itemsPerSheet: number;
  metaDescription?: string;
  metaTitle?: string;
  popularity: number;
  printtotheedge?: boolean;
  productCategoryType?: string;
  sheetSize?: string;
  size?: string;
  sizeRange?: string;
  sortOrder: number;
  templateNumber: number;
  templateUri: string;
  trademark?: string;
  trademarkTemplateNumber: string;
  type1: string;
  width?: number;
}

interface TemplateFilterInterface {
  categoryTemplates: any[];
  filters: any[];
  selectedFilters: object;
  selectedToggles: any[];
  sort: string;
}

export interface TemplateState {
  totalTemplates: Template[];
  filter: TemplateFilterInterface;
  detail: GQLTemplate;
}

export interface TemplateCategories {
  categories: TemplateCategory[];
  viewMore: TemplateCategory;
}

export interface TemplateCategory {
  alt?: string;
  name: string;
  imgSrc: string;
  label: string;
}

export interface Category {
  name: string;
  label: string;
}
