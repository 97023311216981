import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { FormGroup } from '@angular/forms';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import * as bowser from 'bowser';
import * as localForage from 'localforage';
import isEmpty from 'lodash-es/isEmpty';
import unescape from 'lodash-es/unescape';

import { environment } from '../../environments/environment';
import { checkAppThemeMode } from './helpers';

import {
  BlankFilterTypeaheadRes,
  BlankLabelTypeaheadEntry,
  TemplateTypeahead,
} from 'navigation/nav-shared/models/interfaces/typeahead.interface';

@Injectable({
  providedIn: 'root',
})
export class NavigationService {
  detectBrowser: bowser.IBowser = bowser;
  isMobileView = new BehaviorSubject<boolean>(false);
  isTabletView = new BehaviorSubject<boolean>(false);
  localForage: LocalForage;
  multiSiteData = new BehaviorSubject<any>(null);
  navLinkData = new BehaviorSubject<any>(null);
  themeMode = new BehaviorSubject<string>(null);
  routeChanged = new EventEmitter<string>();

  constructor(private http: HttpClient, private sanitizer: DomSanitizer) {
    this.setThemeMode();
    this.localForage = localForage.createInstance({ name: 'Avery Search' });
  }

  emitRouteChange(route: string) {
    this.routeChanged.emit(route);
  }

  /**
   * Checks if a cookie for a theme exists or if the route/URL path corresponds to a specific theme
   *
   * @returns {Promise<string>}
   * @memberof NavigationService
   */
  checkThemeMode(): Promise<string> {
    return checkAppThemeMode();
  }

  /**
   * Sets the themeMode for the BS
   *
   * @memberof NavigationService
   */
  async setThemeMode() {
    const theme = await this.checkThemeMode();
    this.themeMode.next(theme);
  }

  /**
   * Sets the theme mode without checking the thememode.
   *
   * @param strThemeMode
   * @memberof NavigationService
   */
  setThemeModeSync(strThemeMode: string) {
    this.themeMode.next(strThemeMode);
  }

  /**
   * Gets country code from the `cloudfront-viewer-country` response header.
   *
   * NOTE: The Country Code (`cloudfront-country-viewer`) header is only available on production. But it is also available on `https://dev2-cms.avery.com` for testing.
   *
   * @returns {Observable<any>}
   * @memberof NavigationService
   */
  getCountryCode(): Observable<any> {
    const endpoint: string =
      environment.environment === 'develop'
        ? 'https://dev2-cms.avery.com/'
        : 'https://www.avery.com/';

    const options: any = {
      observe: 'response',
      responseType: 'text',
    };

    return this.http.get<any>(endpoint, options);
  }

  /**
   * Set the window
   *
   * @returns window
   * @memberof NavigationService
   */
  getNativeWindow() {
    return window;
  }

  /**
   * Sort the Navigation data
   *
   * @param { any } data
   * @param {string} sortBy
   * @param {string } theme
   * @memberof NavigationService
   */
  sortNavData(data: any, sortBy: string, theme?: string) {
    return data
      .filter((obj) => {
        return obj[sortBy] > 0;
      })
      .filter((obj) => {
        if (theme) {
          return obj.theme === this.themeMode.value || obj.theme === 'all';
        }

        return obj;
      })
      .sort((a: number, b: number) => {
        if (a[sortBy] < b[sortBy]) {
          return -1;
        } else if (a[sortBy] > b[sortBy]) {
          return 1;
        } else {
          return 0;
        }
      });
  }

  /**
   * Get Avery Custom Generic content from dotcms
   *
   * @param {type} string
   * @param {urlTitle} string
   * @returns {Observable | json}
   * @memberof NavigationService
   */
  fetchAveryCustomGenericContent(
    urlTitle: string,
    type: string
  ): Observable<any> {
    return this.http.get(
      `${environment.domain}/rest/custom/acg/${type}/${urlTitle}`
    );
  }

  /**
   * Sanitize HTML Content
   *
   * @param {data} string
   * @returns {SafeHtml}
   * @memberof NavigationService
   */
  sanitizeContent(data: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(unescape(data));
  }

  /**
   * Fetch Multi Site Dropdown data
   *
   * @returns {Observable<any>}
   * @memberof NavigationService
   */
  fetchMultiSiteData(): Observable<any> {
    return this.http.get(`${environment.domain}/app/json/multisite-links.json`);
  }

  /**
   * Detect and return the environment domain
   *
   * @returns {string}
   * @memberof NavigationService
   */
  appUrl(): string {
    // environment domain as variable
    let appUrl = environment.appDomain;
    // TODO: Remove window object
    if (
      /^https?:\/\/(dev1-cms|dev2-cms|dev3-cms|dev4-cms|dev5-cms)\.avery\.com.*$/.test(
        window.location.origin
      )
    ) {
      appUrl = window.location.origin;
    }
    return appUrl;
  }

  /**
   * Take a route and generate the full link
   * based off the environment
   *
   * @param {string} route
   * @returns {string}
   * @memberof NavigationService
   */
  generateLink(route: string): string {
    // check if route has an absolute path
    if (/http/.test(route)) {
      return route;
    } else {
      // add environment domain to local routes
      return `${environment.appDomain}${route}`;
    }
  }

  /**
   * Navigate to route.
   *
   * @param {string} route
   * @param {any} queryString
   * @memberof NavigationService
   */
  navigateToRoute(
    pathname: string,
    queryParams: Record<string, string> = null
  ) {
    if (pathname.includes('/checkout/cart')) {
      window.location.href = environment.magento + pathname;
    } else {
      window.location.href = environment.domain + pathname;
    }
  }

  /**
   * Determine whether the route would be a part of the Angular app
   *
   * TODO: Use regex to consolidate routes (start with blank and also include blank detail)
   *
   * @param {string} route
   * @returns {boolean}
   * @memberof NavigationService
   */
  routeIsInAngularApp(route: string): boolean {
    switch (true) {
      case route.indexOf('/blank/labels') === 0:
      case route.indexOf('/blank/material') === 0:
      case route.indexOf('/blank/shape') === 0:
      case route.indexOf('/blank/usage') === 0:
      case route.indexOf('/custom-printing/labels/calculator') === 0:
      case route.indexOf('/custom-printing/labels/rolls') === 0:
      case route.indexOf('/custom-printing/predesign') === 0:
      // TODO: Update the transparency route
      case route.indexOf('/custom-printing/transparency') === 0:
      case route.indexOf('/myaccount') === 0:
      case route.indexOf('/predesign') === 0:
      case route.indexOf('/products') === 0:
      case route.indexOf('/secure') === 0:
      case route.indexOf('/srch') === 0:
      case route.indexOf('/templates') === 0:
        return true;
      default:
        return false;
    }
  }

  /**
   * Checks to see if the current host is the Magento app.
   *
   * @return {boolean}
   * @memberOf NavigationService
   */
  routeIsMagentoApp(): boolean {
    return environment.magento.includes(this.getNativeWindow().location.host);
  }

  /**
   * Makes a GET request to magento.
   *
   * @return {Observable<Object>}
   * @memberOf NavigationService
   */
  getMagento() {
    return this.http.get(environment.magento);
  }

  /**
   * Return true if breakpoint is sm or xs
   *
   * @memberof NavigationService
   */
  isMobile() {
    const breakpoint = window
      .getComputedStyle(document.querySelector('.header'), ':before')
      .getPropertyValue('content')
      .replace(/[\"|\']/g, '');
    if (breakpoint === 'xs' || breakpoint === 'sm') {
      return true;
    }
    return false;
  }

  /**
   * Return specific breakpoint
   *
   * @memberof NavigationService
   */
  isTablet() {
    const breakpoint = window
      .getComputedStyle(document.querySelector('.header'), ':before')
      .getPropertyValue('content')
      .replace(/[\"|\']/g, '');
    if (breakpoint === 'xs' || breakpoint === 'sm' || breakpoint === 'md') {
      return true;
    }
    return false;
  }

  /**
   * Fetch custom card data
   *
   * @param {string} card
   * @returns {Observable<any>}
   * @memberof NavigationService
   */
  fetchCustomCardData(card: string): Observable<any> {
    return this.http.get(
      `${environment.domain}/rest/custom/modal-size-content/${card}`,
      {
        withCredentials: true,
        responseType: 'json',
      }
    );
  }

  /**
   * Fetches ACG (Avery Custom Generic) data from dotCMS API.
   *
   * @param {string} acg
   * @returns {Observable<any>}
   * @memberof NavigationService
   */
  fetchGeneralModalData(type: string, url: string): Observable<any> {
    return this.http.get(
      `${environment.domain}/rest/custom/acg/${type}/${url}`
    );
  }

  /**
   * Fetches online aisle sample pack materials data from dotCMS.
   *
   * @returns {Observable<any>}
   * @memberof NavigationService
   */
  fetchOASamplePackMaterials(): Observable<any> {
    return this.http.get(
      `${environment.domain}/rest/blank/config/blank-label-filters`
    );
  }

  /**
   * Fetches Avery NG config data from dotCMS.
   *
   * @param {string} name
   * @returns {Observable<any>}
   * @memberof NavigationService
   */
  fetchCustomResourceData(name: string): Observable<any> {
    return this.http.get(`${environment.domain}/rest/ng/page/${name}`);
  }

  /**
   * Fetch sample pack data
   *
   * @returns {Observable<any>}
   * @memberof NavigationService
   */
  fetchSamplePackData(): Observable<any> {
    return this.http.get(`${environment.domain}/app/json/form-elements.json`);
  }

  /**
   * Gets the client Browser Name
   *
   * @returns
   * @memberof NavigationService
   */
  getClientBrowserName() {
    return this.detectBrowser.name;
  }

  /**
   * Returns the sample pack forms data from local forage.
   * @return {Promise<any> | void}
   * @memberof NavigationService
   */
  getSamplePackData(): Promise<any> {
    return this.localForage.getItem<any>('samplePackFormData');
  }

  /**
   * Sets the sample pack forms data into local forage.
   * @param data {any}
   * @memberOf NavigationService
   */
  setSamplePackData(data: any) {
    this.localForage.setItem('samplePackFormData', data);
  }

  /**
   * Create an object of additional sample pack fields based on flag either WP or GHS.
   *
   * @param {FormGroup} form
   * @param {boolean} isWP
   * @returns
   * @memberof NavigationService
   */
  setSamplePackOtherFields(form: FormGroup, isWP: boolean) {
    const newObj = {};
    let aryStrings = [
      'companyName',
      'address1',
      'address2',
      'cityName',
      'stateName',
      'zipCode',
    ];
    let aryAttributes = [
      'company',
      'address1',
      'address2',
      'city',
      'region',
      'postcode',
    ];
    aryStrings.forEach((element, idx) => {
      if (!isEmpty(form.controls[element].value)) {
        newObj[aryAttributes[idx]] = form.controls[element].value;
      }
    });
    if (!isWP) {
      return newObj;
    } else {
      const obj = {};
      aryStrings = ['industryField', 'referralSource', 'phoneNumber'];
      aryAttributes = ['industry', 'hearAboutUs', 'phone'];
      aryStrings.forEach((element, idx) => {
        if (!isEmpty(form.controls[element].value)) {
          obj[aryAttributes[idx]] = form.controls[element].value;
        }
      });
      return { ...newObj, ...obj };
    }
  }

  /**
   * Function to check valid value for a numeric only keyboard.
   *
   * @param {event}
   * @return {boolean}
   * @memberof NavigationService
   */
  numericKeyboard(event): boolean {
    // NOTE: Valid Keys are: 0~9 (keycodes: 48~57 and 96~105[windows numberpad]), left and right arrow keys, shift, caps-lock, backspace, and delete keys
    // NOTE: `event.key` is the recomended property but some browsers might still not support this property. `event.keyIdentifier` or `event.keyCode` will be the fall back.
    if (event.key) {
      return (
        (Number.isFinite(Number(event.key)) && event.key !== ' ') ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        event.key === 'Backspace' ||
        event.key === 'CapsLock' ||
        event.key === 'Del' ||
        event.key === 'Shift'
      );
    } else if (event.keyIdentifier) {
      const keyValue = event.keyIdentifier.includes('U+')
        ? String.fromCharCode(event.keyIdentifier.replace('U+', '0x'))
        : event.keyIdentifier;

      return (
        (Number.isFinite(Number(keyValue)) &&
          Number(keyValue) >= 0 &&
          Number(keyValue) <= 9 &&
          event.keyIdentifier !== 'U+0020') ||
        keyValue === 'Left' ||
        keyValue === 'Right' ||
        event.keyIdentifier === 'U+0008' ||
        event.keyIdentifier === 'U+007F'
      );
    } else if (event.keyCode) {
      return (
        (Number.isFinite(Number(String.fromCharCode(event.keyCode))) &&
          event.keyCode !== 32) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode === 8 || // backspace key
        event.keyCode === 16 || // shift key
        event.keyCode === 20 || // capslock key
        event.keyCode === 37 || // left arrow
        event.keyCode === 39 || // right arrow
        event.keyCode === 46
      ); // delete key
    }

    return false;
  }

  /**
   * Function to check for the "enter" key.
   *
   * @param {any} event
   * @return {boolean}
   * @memberof NavigationService
   */
  enterKeyBoard(event: any) {
    if (event.key) {
      return event.key === 'Enter';
    } else if (event.keyIdentifier) {
      return event.keyIdentifier === 'Enter';
    } else if (event.keyCode) {
      return event.keyCode === 13; // Enter
    }

    return false;
  }

  /**
   * Fetch sample pack flags for the toggles.
   *
   * @returns {Observable<any>}
   * @memberof NavigationService
   */
  fetchSamplePackToggles(): Observable<any> {
    return this.http.get(
      `${environment.domain}/app/json/sample-pack-toggles.json`
    );
  }

  /**
   * Returns the sample pack config data from local forage.
   * @return {Promise<any> | void}
   * @memberof NavigationService
   */
  getSamplePackToggles(): Promise<any> {
    return this.localForage.getItem<any>('samplePackConfigData');
  }

  /**
   * Sets the sample pack config data into local forage.
   * @param data {any}
   * @memberof NavigationService
   */
  setSamplePackToggles(data: any) {
    this.localForage.setItem('samplePackConfigData', data);
  }

  /**
   * API to get the CPG data used for the search outside of BLFP
   *
   * @returns {Observable<BlankFilterTypeaheadRes>}
   * @memberof NavigationService
   */
  getBlankLabelsCPGTypeahead(): Observable<BlankLabelTypeaheadEntry[]> {
    return this.http
      .get<BlankFilterTypeaheadRes>(`${environment.domain}/rest/labels`)
      .pipe(map(({ contentlets }: BlankFilterTypeaheadRes) => contentlets));
  }

  /**
   * API to get the OA data used for the seawrch outside of BLFP
   *
   * @param {string} [theme='default']
   * @returns {Observable<BlankLabelTypeaheadEntry[]>}
   * @memberof NavigationService
   */
  getBlankLabelsOATypeAhead(
    theme = 'default'
  ): Observable<BlankLabelTypeaheadEntry[]> {
    return this.http
      .get(`${environment.domain}/rest/blank/${theme}/labels`)
      .pipe(
        map((data: any) => {
          return data.contentlets
            .sort((a, b) => b.popularity - a.popularity)
            .map(({ size, defaultMediaUrl, productNumber }) => {
              return {
                size,
                image: defaultMediaUrl,
                sku: productNumber,
                type: 'OA',
              };
            });
        })
      );
  }

  /**
   * Function to get template data used for the template search typeahead
   *
   * @returns {Observable<TemplateTypeahead>}
   * @memberof NavigationService
   */
  getTemplateTypeahead(): Observable<TemplateTypeahead> {
    return this.http.get<TemplateTypeahead>(
      `${environment.domain}/rest/template/typeahead`
    );
  }

  /**
   * Dpo service to get custom size templateId
   * @param {HttpParams} params
   * @return {Observable<any>}
   * @memberof NavigationService
   */
  getCustomTemplateId(params: HttpParams): Observable<any> {
    const deploymentId = environment.dpoDeploymentID;
    params = params
      .append('consumer', 'Avery')
      .append('deploymentId', deploymentId);
    return this.http.get(`${environment.dpo4}/template-ids/search`, { params });
  }

  /**
   * Checks if the current path matches atleast one path supplied in an array of possibilities.
   *
   * TODO: Can expand to accept regex possibilites.. ATM just a full path match.
   *
   * @param {Array<string>} paths
   * @returns {boolean}
   * @memberof NavigationService
   */
  checkRouteMatch(routes: Array<string>): boolean {
    return routes.some((route) => route === window.location.pathname);
  }

  /**
   * Fetch the json file being passed.
   *
   * @returns {Observable<any>}
   * @memberof NavigationService
   */
  fetchConfigData(file: string): Observable<any> {
    return this.http.get(`${environment.domain}/app/json/${file}`);
  }

  /**
   * Returns the passed file name data from local forage.
   * @return {Promise<any> | void}
   * @memberof NavigationService
   */
  getDataFromLF(file: string): Promise<any> {
    return this.localForage.getItem<any>(file);
  }

  /**
   * Sets the passed file name config data into local forage.
   * @param data {any}
   * @memberof NavigationService
   */
  setDataToLF(file: string, data: any) {
    this.localForage.setItem(file, data);
  }
}
