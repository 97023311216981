import { Inject, Injectable, Renderer2 } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { DOCUMENT, Location } from '@angular/common';
import { DomSanitizer, SafeHtml, Title } from '@angular/platform-browser';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import isEmpty from 'lodash-es/isEmpty';
import unescape from 'lodash-es/unescape';
import * as bowser from 'bowser';

import { localStorageGetter, localStorageSetter } from './shared/helpers';
import { environment } from 'environments/environment';
import { AveryCustomGenericCollection, GetACGQuery } from './graphql';
import { ACG } from './shared/models/interfaces';
import {
  DOMAIN_REGEX,
  EMAIL_REGEX,
  IP_DOMAIN_REGEX,
  MAX_USERNAME_LEN,
  USER_REGEX,
  emitCoreDataLayer,
} from 'navigation/nav-shared/helpers';

@Injectable()
export class AppService {
  viewType = new BehaviorSubject<string>('grid');
  detectBrowser: bowser.IBowser = bowser;

  constructor(
    @Inject(DOCUMENT) private _document,
    private apollo: Apollo,
    private http: HttpClient,
    private location: Location,
    private sanitizer: DomSanitizer,
    private titleService: Title
  ) {}

  /**
   * Sets the window
   *
   * @returns window
   * @memberof HeaderService
   */
  getNativeWindow() {
    return window;
  }

  /**
   * Function used to make Elastic Search queries to the dotCMS backend. The queries parameter
   * requires a certain structure. Please look at queryGenerator() below to see how it's done
   *
   * NOTE: For labels and cards look at products-filter.service.ts
   *
   * TODO: Move into products.service
   *
   * @param {*} queries
   * @param {string} contentType
   * @param {boolean} [searchProduct=false]
   * @param {string} [themeMode='default']
   * @returns {Observable<any>}
   * @memberof AppService
   */
  elasticSearch(
    queries: any,
    contentType: string = '',
    searchProduct: boolean = false,
    themeMode: string = 'default'
  ): Observable<any> {
    queries = this.queryGenerator(queries);

    if (searchProduct) {
      queries.size = 2000;
    }

    if (themeMode === 'industrial') {
      queries.query.bool.must_not = [{ term: { [`${contentType}.configType`]: 'default' } }];
    }

    return this.http.post(`${environment.domain}/api/es/search`, queries);
  }

  /**
   * Http call to get the pricing data from magento
   *
   * @param {string} sku
   * @returns {Observable<any>}
   * @memberof AppService
   */
  getProductPricing(sku: string): Observable<any> {
    return this.http.get(
      `${environment.magento}/rest/V1/avery/connect/products/${sku}/price/weprint?&v=2`
    );
  }

  /**
   * GraphQL call to get ACGs
   *
   * @param {string[]} names
   * @param {boolean} [sanitize=true]
   * @returns {(Observable<ACG<SafeHtml>[] | ACG<string>[]>)}
   * @memberof AppService
   */
  getACGs(names: string[], sanitize = true): Observable<ACG<SafeHtml>[] | ACG<string>[]> {
    return this.apollo
      .query<AveryCustomGenericCollection>({
        query: GetACGQuery,
        variables: {
          query: `+Averycustomgeneric.name:("${names.join('" "')}")`,
        },
      })
      .pipe(
        map(({ data }) => {
          if (sanitize) {
            return data.averycustomgenericCollection.map(({ name, body }) => ({
              name,
              body: this.sanitizeContent(body),
            }));
          }

          return data.averycustomgenericCollection;
        })
      );
  }

  /**
   * Get Avery Custom Generic content from dotcms
   *
   * @param {type} string
   * @param {urlTitle} string
   * @returns {Observable | json}
   * @memberof AppService
   */
  fetchCustomGeneric(type: string, urlTitle: string): Observable<any> {
    return this.http.get(`${environment.domain}/rest/custom/acg/${type}/${urlTitle}`);
  }

  /**
   * Sanitize HTML Content
   *
   * @param {data} string
   * @returns {SafeHtml}
   * @memberof AppService
   */
  sanitizeContent(data: string): SafeHtml {
    return this.sanitizer.bypassSecurityTrustHtml(unescape(data));
  }

  /**
   * Function used to make queries to dotCMS. Insert an array of key-value pairs
   * Example input: [{ 'contenttype' : 'marker' }, { 'marker.upcNumber': '7170924406' }]
   *
   * @param {*} queries
   * @returns {*}
   * @memberof AppService
   */
  queryGenerator(queries: any): any {
    const must = [];

    queries.forEach((term) => must.push({ term }));

    return {
      query: {
        bool: { must },
      },
    };
  }

  /**
   * Change the view to display grid or list
   *
   * @param {string} type
   * @memberof AppService
   */
  changeView(type: string) {
    this.viewType.next(type);
    const config = localStorageGetter('filterConfig') || { viewType: '' };
    config.viewType = type;
    localStorageSetter('filterConfig', config);
  }

  /**
   * Checks if Browser is Internet Explorer
   *
   * @memberof AppService
   */
  checkForIE() {
    return this.detectBrowser.name === 'Internet Explorer';
  }

  /**
   * Function to check valid value for a numeric only keyboard.
   *
   * @param {event}
   * @return {boolean}
   * @memberof AppService
   */
  numericKeyboard(event): boolean {
    // NOTE: Valid Keys are: 0~9 (keycodes: 48~57 and 96~105[windows numberpad]), left and right arrow keys, shift, caps-lock, backspace, and delete keys
    // NOTE: `event.key` is the recomended property but some browsers might still not support this property. `event.keyIdentifier` or `event.keyCode` will be the fall back.
    if (event.key) {
      return (
        (Number.isFinite(Number(event.key)) && event.key !== ' ') ||
        event.key === 'ArrowLeft' ||
        event.key === 'ArrowRight' ||
        event.key === 'Backspace' ||
        event.key === 'CapsLock' ||
        event.key === 'Del' ||
        event.key === 'Shift' ||
        event.key === 'Tab'
      );
    } else if (event.keyIdentifier) {
      const keyValue = event.keyIdentifier.includes('U+')
        ? String.fromCharCode(event.keyIdentifier.replace('U+', '0x'))
        : event.keyIdentifier;

      return (
        (Number.isFinite(Number(keyValue)) &&
          Number(keyValue) >= 0 &&
          Number(keyValue) <= 9 &&
          event.keyIdentifier !== 'U+0020') ||
        keyValue === 'Left' ||
        keyValue === 'Right' ||
        event.keyIdentifier === 'U+0008' ||
        event.keyIdentifier === 'U+007F'
      );
    } else if (event.keyCode) {
      return (
        (Number.isFinite(Number(String.fromCharCode(event.keyCode))) && event.keyCode !== 32) ||
        (event.keyCode >= 96 && event.keyCode <= 105) ||
        event.keyCode === 8 || // backspace key
        event.keyCode === 9 || // Tab key
        event.keyCode === 16 || // shift key
        event.keyCode === 20 || // capslock key
        event.keyCode === 37 || // left arrow
        event.keyCode === 39 || // right arrow
        event.keyCode === 46
      ); // delete key
    }

    return false;
  }

  /**
   * Function to check for the "enter" key.
   *
   * @param {any} event
   * @return {boolean}
   * @memberof AppService
   */
  enterKeyBoard(event: any) {
    if (event.key) {
      return event.key === 'Enter';
    } else if (event.keyIdentifier) {
      return event.keyIdentifier === 'Enter';
    } else if (event.keyCode) {
      return event.keyCode === 13; // Enter
    }

    return false;
  }

  /**
   * Checks whether a keyboard event is a ctrl action (e.g. copy/paste)
   *
   * @param {KeyboardEvent} { ctrlKey, metaKey, key }
   * @returns {boolean}
   * @memberof AppService
   */
  isCtrlAction({ ctrlKey, metaKey, key }: KeyboardEvent): boolean {
    switch (key) {
      case 'a':
      case 'c':
      case 'v':
      case 'x':
      case 'z':
      case 'Control':
      case 'Meta':
        return ctrlKey || metaKey;
    }

    return false;
  }

  /**
   * Validates a string for emails using regex
   * @param {string} strEmail
   * @return {boolean}
   * @memberof AppService
   */
  validEmail(strEmail: string): boolean {
    const emailMatcher = EMAIL_REGEX.exec(strEmail);
    if (isEmpty(emailMatcher)) {
      return false;
    }
    // here we will validate the user and domain
    return this.isValidUser(emailMatcher[1]) && this.isValidDomain(emailMatcher[2]);
  }

  /**
   * Returns true if the domain component of an email address is valid.
   * @param {string} domain
   * @return {boolean}
   * @memberof AppService
   */
  isValidDomain(domain: string): boolean {
    // see if domain is an IP address in brackets
    if (domain === null) {
      return false;
    }

    return IP_DOMAIN_REGEX.test(domain) || DOMAIN_REGEX.test(domain);
  }

  /**
   * Returns true if the user component of an email address is valid.
   * @param {string} user
   * @return {boolean}
   * @memberof AppService
   */
  isValidUser(user: string): boolean {
    if (user === null || user.length > MAX_USERNAME_LEN) {
      return false;
    }
    return USER_REGEX.test(user);
  }

  /**
   * Sets a dynamic title. Also emits a data layer push event for GTM
   *
   * @param {string} [title='Avery']
   * @param {boolean} [pushDataLayer=true]
   * @memberof AppService
   */
  setTitle(title: string = 'Avery', pushDataLayer: boolean = true) {
    this.titleService.setTitle(title);
    if (pushDataLayer) {
      emitCoreDataLayer();
    }
  }

  /**
   * Set Canonical URL using the parameters passed here.
   *
   * @param {Renderer2} renderer
   * @param {string} [type='default']
   * @param {boolean} [insertBefore=true]
   * @param {string} [preformattedPath=null]
   * @param {string} [preset=null]
   * @param {string} [format=null]
   * @returns
   * @memberof AppService
   */
  addCanonicalURL(
    renderer: Renderer2,
    type: string = 'default',
    insertBefore: boolean = true,
    preformattedPath: string = null,
    preset: string = null,
    format: string = null
  ) {
    let url = preformattedPath
      ? `${environment.domain}${preformattedPath}`
      : `${environment.domain}${this.location.path()}`;

    if (type === 'default' || isEmpty(type)) {
      // Replaces /industrial with empty string
      url = url.replace(/\/\b(industrial)\b/gi, '');
    } else {
      // For industrial product or template type, if url has no /industrial, append it.
      if (this.location.path().indexOf('/industrial') === -1) {
        url = `${environment.domain}/industrial${this.location.path()}`;
      }
    }

    // url for default calculator keeps /sheets/, /rolls/, /stickers/
    // Removes `/sheets/` or `/rolls/` or `/stickers/` for preset calculator
    !preset && format ? url : (url = url.replace(/\/(\bsheets|rolls|stickers\b)\/?$/g, ''));

    // Remove trailing question marks
    url = url.replace(/(\?)+$/, '');

    const canonicalURLElement = renderer.createElement('link');
    const head = this._document.head;

    // Removes previously added canonical url before adding a new one to avoid duplicate
    this._document.querySelectorAll('link[rel="canonical"]').forEach((link) => {
      renderer.removeChild(head, link);
    });

    renderer.setAttribute(canonicalURLElement, 'rel', 'canonical');
    renderer.setAttribute(canonicalURLElement, 'href', url);
    if (insertBefore) {
      renderer.insertBefore(head, canonicalURLElement, head.firstChild);
    } else {
      renderer.appendChild(head, canonicalURLElement);
    }

    return canonicalURLElement;
  }

  /**
   * Capitalizes the first character in a string value.
   *
   * @param strValue
   * @return string
   * @memberOf AppService
   */
  capitalizeFirstLetter(strValue: string) {
    return strValue.charAt(0).toUpperCase() + strValue.slice(1);
  }

  /**
   * This function publishes a message to a averycom generic topic.
   *
   * @param {*} obj
   * @returns {Observable<any>}
   * @memberof AppService
   */
  publishGenericTopic(obj: any): Observable<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/json' });
    const options = {
      headers,
      withCredentials: true,
    };

    return this.http.post(`${environment.sampleService}/publish-generic-topic`, obj, options);
  }
}
