import { gql } from 'apollo-angular';

export interface AveryCustomGenericCollection {
  averycustomgenericCollection: { name: string; body: string }[];
}

export const GetACGQuery = gql`
  query GetACG($query: String!) {
    averycustomgenericCollection(query: $query) {
      name
      body
    }
  }
`;
