import { Action as ngrxAction } from '@ngrx/store';
/**
 * Action constants should be kept in one file. It allows (new) users to see all possible ngrx
 * actions in the entire application just by looking at this file.
 */

export interface Action extends ngrxAction {
  payload?: any;
}

// These actions occur on the filter page
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const RETRIEVE_FILTERS = 'RETRIEVE_FILTERS';
export const SELECT_PRODUCT_FILTERS = 'SELECT_PRODUCT_FILTERS';
export const PARAM_FILTERS = 'PARAM_FILTERS';
export const APPLY_FILTER = 'APPLY_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const RESET_FILTERS = 'RESET_FILTERS';
export const FILTER_PRODUCTS = 'FILTER_PRODUCTS';
export const REPLACE_FILTER_HEADER = 'REPLACE_FILTER_HEADER';
export const TOGGLE_FILTER_ON = 'TOGGLE_FILTER_ON';
export const TOGGLE_FILTER_OFF = 'TOGGLE_FILTER_OFF';
export const PARAM_TOGGLES = 'PARAM_TOGGLES';
export const RESET_TOGGLES = 'RESET_TOGGLES';
export const UPDATE_SORT = 'UPDATE_SORT';
export const RESET_SORT = 'RESET_SORT';
export const RESET_PRODUCT_STATE = 'RESET_PRODUCT_STATE';

// These actions occur on a product detail page (SELECT_PRODUCT also exists on filter page)
export const SELECT_PRODUCT = 'SELECT_PRODUCT';
export const GET_PRODUCT_IMAGES = 'GET_PRODUCT_IMAGES';
export const SELECT_IMAGE = 'SELECT_IMAGE';
export const GET_PRODUCT_GROUP = 'GET_PRODUCT_GROUP';
export const RESET_PRODUCT = 'RESET_PRODUCT';

// Actions for the Template pages
export const RETRIEVE_ALL_TEMPLATES = 'RETRIEVE_ALL_TEMPLATES';
export const SELECT_TEMPLATE = 'SELECT_TEMPLATE';
export const RETRIEVE_CATEGORY_TEMPLATES = 'RETRIEVE_CATEGORY_TEMPLATES';
export const GET_TEMPLATE_FILTER = 'GET_TEMPLATE_FILTER';
export const TOGGLE_TEMPLATE_FILTER_ON = 'TOGGLE_TEMPLATE_FILTER_ON';
export const TOGGLE_TEMPLATE_FILTER_OFF = 'TOGGLE_TEMPLATE_FILTER_OFF';
export const APPLY_TEMPLATE_FILTER = 'APPLY_TEMPLATE_FILTER';
export const REMOVE_TEMPLATE_FILTER = 'REMOVE_TEMPLATE_FILTER';
export const PARAM_TEMPLATE_FILTERS = 'PARAM_TEMPLATE_FILTERS';
export const PARAM_TEMPLATE_TOGGLES = 'PARAM_TEMPLATE_TOGGLES';
export const RESET_TEMPLATE_FILTERS = 'RESET_TEMPLATE_FILTERS';
export const UPDATE_TEMPLATE_SORT = 'UPDATE_TEMPLATE_SORT';
export const CHANGE_FILTERS = 'CHANGE_FILTERS';
export const RESET_TEMPLATE = 'RESET_TEMPLATE';

// Action that will occur in the my-account/project page
export const GET_PROJECTS = 'GET_PROJECTS';
export const GET_FILTER_PROJECT_OPTIONS = 'GET_FILTER_PROJECT_OPTIONS';
export const DISPLAY_MORE_PROJECTS = 'DISPLAY_MORE_PROJECTS';
export const UPDATE_PROJECT = 'UPDATE_PROJECT';
export const COPY_PROJECT = 'COPY_PROJECT';
export const DELETE_PROJECT = 'DELETE_PROJECT';

// Actions for File store
export const GET_FILES = 'GET_FILES';
export const DISPLAY_MORE_FILES = 'DISPLAY_MORE_FILES';
export const UPDATE_FILES = 'UPDATE_FILES';
export const COPY_FILES = 'COPY_FILES';
export const DELETE_FILES = 'DELETE_FILES';

// Action for Stencil store
export const GET_STENCILS = 'GET_STENCILS';
export const DISPLAY_MORE_STENCILS = 'DISPLAY_MORE_STENCILS';
// Actions that will occur in the myaccount/profile page
export const GET_PROFILE_EMAIL = 'GET_PROFILE_EMAIL';
export const GET_PROFILE_NAME = 'GET_PROFILE_NAME';
export const GET_PROFILE = 'GET_PROFILE';
export const UPDATE_PROFILE_STATUS = 'UPDATE_PROFILE_STATUS';

// Action that will occur in the my-account/orders page
export const ADD_ORDERS = 'ADD_ORDERS';
export const ADD_APPROVAL_ORDERS = 'ADD_APPROVAL_ORDERS';
export const ADD_IN_PRODUCTION_ORDERS = 'ADD_IN_PRODUCTION_ORDERS';
export const ADD_PREP_SHIPPING_ORDERS = 'ADD_PREP_SHIPPING_ORDERS';
export const ADD_SHIPPED_ORDERS = 'ADD_SHIPPED_ORDERS';
export const ADD_CANCELLED_ORDERS = 'ADD_CANCELLED_ORDERS';
export const DISPLAY_MORE_ORDERS = 'DISPLAY_MORE_ORDERS';
export const UPDATE_ORDERS = 'UPDATE_ORDERS';
export const UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS';
export const UPDATE_ORDER_SORT = 'UPDATE_ORDER_SORT';
export const UPDATE_ORDER_TOTAL = 'UPDATE_ORDER_TOTAL';
export const REFRESH_ORDER_LIST = 'REFRESH_ORDER_LIST';
export const UPDATE_ORDER_ITEM_VERSION = 'UPDATE_ORDER_ITEM_VERSION';
export const UPDATE_ORDER_ITEM_VERSIONS = 'UPDATE_ORDER_ITEM_VERSIONS';
export const UPDATE_ORDER_TIME = 'UPDATE_ORDER_TIME';
export const UPDATE_ORDER_PERIODS = 'UPDATE_ORDER_PERIODS';

// These actions occur on a transparency pages
export const TRANS_UPDATE_SELECTION = 'TRANS_UPDATE_SELECTION';
export const TRANS_SELECT_SIZE = 'TRANS_SELECT_SIZE';
export const TRANS_SELECTIONS_VISIBILITY = 'TRANS_SELECTIONS_VISIBILITY';
export const TRANS_RESET_SELECTION = 'TRANS_RESET_SELECTION';
export const TRANS_RESET_PRICE = 'TRANS_RESET_PRICE';
export const TRANS_UPDATE_UNWIND = 'TRANS_UPDATE_UNWIND';
export const TRANS_UPDATE_HANDAPPLIED = 'TRANS_UPDATE_HANDAPPLIED';
export const TRANS_UPDATE_PRICE = 'TRANS_UPDATE_PRICE';
export const TRANS_UPDATE_QTY = 'TRANS_UPDATE_QTY';
export const TRANS_UPDATE_UNDERPRINT = 'TRANS_UPDATE_UNDERPRINT';

// Actions set for the rolls quote.
export const UPDATE_SHAPE = 'UPDATE_SHAPE';
export const UPDATE_SIZE = 'UPDATE_SIZE';
export const UPDATE_MATERIAL = '';
export const ROLL_UPDATE_ALL = 'ROLL_UPDATE_ALL';

export const ROLL_ADD_ARTWORK = 'ROLL_ADD_ARTWORK';
export const ROLL_CREATE_ARTWORKS = 'ROLL_CREATE_ARTWORKS';
export const ROLL_UPDATE_ARTWORKS = 'ROLL_UPDATE_ARTWORKS';
export const ROLL_UPDATE_ARTWORKS_IMAGE = 'ROLL_UPDATE_ARTWORKS_IMAGE';
export const ROLL_UPDATE_ARTWORKS_QTY = 'ROLL_UPDATE_ARTWORKS_QTY';
export const ROLL_UPDATE_ESTIMATE = 'ROLL_UPDATE_ESTIMATE';
export const ROLL_UPDATE_SELECTION = 'ROLL_UPDATE_SELECTION';
export const RESET_ROLL_ARTWORK = 'RESET_ROLL_ARTWORK';
export const RESET_ROLL_SELECT = 'RESET_ROLL_SELECT';
export const REMOVE_ONE_ARTWORK = 'REMOVE_ONE_ARTWORK';
export const ROLLS_UPDATE_UNWIND = 'ROLLS_UPDATE_UNWIND';
export const ROLLS_UPDATE_PROOF_TYPE = 'ROLLS_UPDATE_PROOF_TYPE';
export const ROLLS_UPDATE_UNDERPRINT = 'ROLLS_UPDATE_UNDERPRINT';
export const ROLLS_UPDATE_HANDAPPLIED = 'ROLLS_UPDATE_HANDAPPLIED';

export const REORDER_CREATE_LIST = 'REORDER_CREATE_LIST';
// TODO: clean up this action file

export const RESET_MY_ACCOUNT = 'RESET_MY_ACCOUNT';
export const INIT_STENCIL_LIST = 'INIT_STENCIL_LIST';
export const INIT_FILE_LIST = 'INIT_FILE_LIST';
export const DISPLAY_MORE_ITEMS = 'DISPLAY_MORE_ITEMS';
export const SET_ITEM_TOTAL = 'SET_ITEM_TOTAL';
export const INIT_PROJECT_LIST = 'INIT_PROJECT_LIST';
export const SET_SORT_TYPE = 'SET_SORT_TYPE';
export const UPDATE_STENCIL = 'UPDATE_STENCIL';
export const COPY_STENCIL = 'COPY_STENCIL';
export const DELETE_STENCIL = 'DELETE_STENCIL';
