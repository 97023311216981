import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { createCookie, getContext, getCookie } from './app/shared/helpers';
import { environment } from './environments/environment';
import { checkAppThemeMode } from './navigation/nav-shared/helpers';

// Duplicate DY cookie as a workaround for Safari's Internet Tracking Prevention (ITP) Policy
// https://support.dynamicyield.com/hc/en-us/articles/360007211797-Safari-s-Internet-Tracking-Prevention-ITP-Policy-
const _dyIdCookie = getCookie('_dyid');
if (_dyIdCookie && !getCookie('_dyid_server')) {
  createCookie('_dyid_server', _dyIdCookie, 365, '.avery.com');
}
const head = document.head;

// Set up Dynamic Yield recommendationContext before script is loaded
(<any>window).DY = (<any>window).DY || {};
try {
  DY.recommendationContext = getContext();
} catch (e) {
  console.error(e);
}

// Lazysizes Script
const lazysizesScript: HTMLScriptElement = document.createElement('script');
lazysizesScript.type = 'text/javascript';
lazysizesScript.src = `${environment.domain}/app/js/lazysizes.min.js`;
lazysizesScript.defer = true;
head.appendChild(lazysizesScript);

// Mega Menu Script
const megaMenuScript: HTMLScriptElement = document.getElementById(
  'avy-lib-mega-menu'
) as HTMLScriptElement;
megaMenuScript.src = `${environment.navDomain}/mega-menu.js`;

// Avery Lib Image Gallery
const avyUIImageGalleryScript: HTMLScriptElement = document.getElementById(
  'avy-lib-image-gallery'
) as HTMLScriptElement;
avyUIImageGalleryScript.src = `${environment.navDomain}/image-gallery.js`;

// Avery Lib Mobile Nav
const avyUIMobileNav: HTMLScriptElement = document.getElementById(
  'avy-lib-mobile-nav'
) as HTMLScriptElement;
avyUIMobileNav.src = `${environment.navDomain}/mobile-nav.js`;

// Avery Live Chat
const avyLiveChat: HTMLScriptElement = document.getElementById(
  'avy-live-chat'
) as HTMLScriptElement;
avyLiveChat.src = `${environment.navDomain}/live-chat-widget.js`;

// Avery Google Badge
const avyGoogleBadge: HTMLScriptElement = document.getElementById(
  'avy-google-badge'
) as HTMLScriptElement;
avyGoogleBadge.src = `${environment.navDomain}/google-review-badge.js`;

// New Nav Style
const megaMenuStyle: HTMLLinkElement = document.getElementById(
  'avy-lib-stylesheet'
) as HTMLLinkElement;
megaMenuStyle.href = `${environment.navDomain}/index.main.css`;

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule);
